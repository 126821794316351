import { PedidosContext } from "./PedidosContext";
import { useState, useEffect } from "react";
import { PedidosApi } from "../../hooks/pedidosApi";

export const PedidosProvider = ({ children }: { children: JSX.Element }) => {
  const api = PedidosApi();

  return (
    <PedidosContext.Provider value={{}}>{children}</PedidosContext.Provider>
  );
};
