import styled, { css } from "styled-components";

const commonStyles = css`
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.brandSecondary};
  max-width: 95vw;
  width: 100rem;

  .dropdown {
    display: contents;
  }

  .titulo {
    margin-top: 2rem;
    font-size: 3rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-weight: bold;
  }

  .cepContainer {
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    padding: 0 0 5rem 0;

    .containerInput {
      width: 21rem;
      margin-right: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 35rem;
      height: 2px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.elements};
      bottom: 0;
    }
  }

  .opcoesDeFrete {
    display: flex;
    padding: 2rem 0 4rem 0;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    row-gap: 2rem;

    .opcao {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0 2rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 35rem;
      height: 2px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.elements};
      bottom: 0;
    }
  }

  .embalagemContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 4rem 15%;

    .larguraAltura {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .inputContainer {
        width: 49%;
      }
    }
  }

  .avancadas {
    color: ${(props) => props.theme.colors.brandPrimary};
    font-size: 2.5rem;
    cursor: pointer;
  }

  .opcoesAvancadasCorreios {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    padding: 2rem 0 4rem 0;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    row-gap: 2rem;

    .opcao {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40rem;
      gap: 1rem;
      padding: 0 2rem;
    }
  }

  .contratoComCorreios {
    position: relative;
    width: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    .avancadas {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 0 0 0;
      text-align: left;
      .input-aux-wrapper {
        top: 100%;
        position: absolute;
        .input-aux {
          width: 0;
          height: 0;
          background-color: rgba(0, 0, 0, 0);
          pointer-events: none;
        }
      }
    }

    .titulo {
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandSecondary};
      font-weight: bold;
      margin-bottom: 2rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 35rem;
      height: 2px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.elements};
      top: 0;
    }

    .grid-inputs {
      display: grid;
      width: 90%;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      gap: 1rem;
      .containerInput {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
      }
    }
  }

  .textoOpcoesCorreios {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    .conteudo {
      max-width: 50rem;
    }
  }
  @media (max-width: 590px) {
    .opcoesDeFrete {
      flex-direction: column;
      row-gap: 1rem;
      .opcao-container{
        display: flex;
        width: 25rem;
        .opcao {
          flex-direction: row-reverse;
          gap: 5rem;
          width: 100%;
          justify-content: space-between;
          padding: 0;
        }
      }
    }
    .embalagemContainer {
      padding: 4rem 4%;
    }
  }
`;

const ContainerConfigCorreios = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      display: block;
      width: 100%;
      max-width: none;
      padding: 0 0rem;
      .textoOpcoesCorreios {
        padding: 2rem 0 0 0;
        justify-content: flex-start;
        .conteudo {
          max-width: 100%;
          text-align: justify;
        }
      }
      .opcoesAvancadasCorreios {
        margin-top: 0;
        justify-content: flex-start;
        .opcao {
          width: 100%;
          justify-content: flex-start;
          padding: 0;
        }
      }
      .contratoComCorreios {
        align-items: flex-start;
        .grid-inputs {
          width: 100%;
          grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
          gap: 1rem;
          @media (max-width: 475px) {
            grid-template-columns: 1fr;
          }
        }
      }
    `}
`;

export default ContainerConfigCorreios;
