import React, { useEffect, useState } from "react";
import { PerfilContext } from "../../contexts/Perfil/PerfilContext";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading/Loading";
import { Select } from "../../components/Select";
import { SlidingBox } from "../../components/SlidingBox";
import { MailBox } from "./mailBox";
import { PhoneBox } from "./phoneBox";
import { PassBox } from "./passBox";
import { PlanBox } from "./planBox";
import { Button } from "../../components/Button";
import { PerfilApi } from "../../hooks/perfilApi";
import ModalSucesso from "../../components/Modal/ModalSucesso";
import Modal from "../../components/Modal/Modal";
import Titulo from "../../components/Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Middle, LeftRight, ViewPerfil, ViewModalPermissoes } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import SkeletonPerfil from "../../components/Skeletons/SkeletonPerfil/SkeletonPerfil";
import { validateEmail, validatePhone } from "../../services/MasksValidations";
import Swal from "sweetalert2";
import Form from "../../components/Form";
import { phoneMask } from "../../services/Masks";
import { unmaskToNumbers } from "../../services/Unmasks";

export const Perfil = () => {
    const Usuarios = React.useContext(PerfilContext);
    const [MeusUsuarios, setMeusUsuarios] = useState<Array<any>>([]);
    const [nomeJaExiste, setNomeJaExiste] = useState(true);
    const [loadingNomeUsuario, setLoadingNomeUsuario] = useState(false);
    const [usuariosNomes, setUsuariosNomes] = useState([
        {
            name: "Gerente",
            value: 1,
        },
    ]); // Nomes dos usuários
    const [usuario, setUsuario] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [periodicidade, setPeriodicidade] = useState({
        periodicidade: "",
        data_inicio: "",
        data_expiracao: "",
    });

    const [modalAtualizando, setModalAtualizando] = useState(false);
    const [mensagemModal, setMensagemModal] = useState(
        "Salvando dados, aguarde...",
    );
    const [stateModal, setStateModal] = useState("loading");
    const [needInit, setNeedInit] = useState(false);
    const [salvarHabilitado, setSalvarHabilitado] = useState(false);

    const [limiteLicencaAtingido, setLimiteLicencaAtingido] = useState(true);
    const [avisoLimiteAtingido, setAvisoLimiteAtingido] = useState(false);

    const [mailBox, setMailBox] = useState(false);
    const [phoneBox, setPhoneBox] = useState(false);
    const [passBox, setPassBox] = useState(false);
    const [planBox, setPlanBox] = useState(false);

    const [modalPermissao, setmodalPermissao] = useState(false);

    const { theme } = useTheme();

    const handleNovoUsuario = () => {
        setMailBox(false);
        setPassBox(false);
        setPhoneBox(false);
        setUsuario({
            nome: "",
            email: "",
            email_notificacao: "",
            senha: "",
            telefone: "",
            tipo: "1",
            novo: true,
        });
    };

    const handleSalvar = async () => {
        PerfilApi()
            .salvarUsuario(usuario)
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Dados salvos com sucesso!",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setModalAtualizando(false);
                setMensagemModal("Dados salvos com sucesso!");
                setStateModal("success");
                setNeedInit(true);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Erro ao salvar os dados",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setModalAtualizando(false);
                setMensagemModal("Erro ao salvar os dados");
                setStateModal("error");
            });
    };

    function removerUsuario() {
        Swal.fire({
            title: "Tem certeza que deseja excluir o usuário?",
            text: "Você não poderá reverter essa ação!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim, excluir!",
        }).then((result) => {
            if (result.isConfirmed) {
                PerfilApi()
                    .removerUsuario(usuario.nome)
                    .then((response) => {
                        setNeedInit(true);
                        Swal.fire({
                            icon: "success",
                            title: "Usuário excluído com sucesso!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Erro ao excluir o usuário",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    }

    async function verificaNomeUsuario(usuario: string) {
        try {
            const response = await PerfilApi().verificaNomeUsuario(usuario);
            setNomeJaExiste(response); // Retorna true se o nome de usuário já estiver sendo usado, ou false se estiver disponível
        } catch (error) {
            return false; // Retorna false em caso de erro
        }
    }


    const handleInfoModal = () => {
        setmodalPermissao(true);
    };

    useEffect(() => {
        const init = async () => {
            const response = await Usuarios.getUsuarios();
            const periodicidade = await PerfilApi().verificaPeriodicidade();
            const limiteLicenca = await PerfilApi().verificaLimiteLicenca();
            setLimiteLicencaAtingido(
                limiteLicenca == "LIMITE_USUARIOS" ? true : false,
            );
            setPeriodicidade(periodicidade);
            setUsuariosNomes(() => {
                return response.map((item: any) => {
                    return {
                        value: item.nome,
                        name: item.nome,
                    };
                });
            });
            setMeusUsuarios(response);

            if (response.length == 1) {
                response[0].tipo = 1;
            }

            setUsuario(response[0]);

            usuario.novo = false;
            setLoading(false);
            setNeedInit(false);
        };

        if (loading || needInit) {
            init();
        }
    }, [usuario, needInit]);

    return (
        <ViewPerfil theme={theme}>
            <LeftRight theme={theme}>
                {loading ? (
                    <SkeletonPerfil />
                ) : (
                    <Form className="edicao-usuario" id="edicao-usuario" setFormValid={setSalvarHabilitado} onSubmit={handleSalvar}>
                        <div className="titulo">Usuário de acesso</div>
                        <div className="campos">
                            {!usuario.novo ? (
                                <div
                                    className={
                                        usuario.novo ||
                                        usuario.nome == MeusUsuarios[0].nome
                                            ? "alterar novo"
                                            : "alterar"
                                    }
                                >
                                    <Select
                                        label="Usuário"
                                        sizeLabel="medium"
                                        options={usuariosNomes}
                                        required={true}
                                        value={usuario.nome}
                                        onChange={(newValue) => {
                                            setUsuario(
                                                MeusUsuarios.find(
                                                    (item: any) =>
                                                        item.nome === newValue,
                                                ),
                                            );
                                        }}
                                    />
                                    {!usuario.novo &&
                                    MeusUsuarios[0].nome != usuario.nome ? (
                                        <div
                                            className="botao_excluir"
                                            onClick={() => {
                                                removerUsuario();
                                            }}
                                        >
                                            Excluir
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <Input
                                    label="Nome"
                                    sizeLabel="medium"
                                    type="text"
                                    name="nome"
                                    required={true}
                                    isValid={{
                                        isValidFunction: (value: string) => {
                                            return nomeJaExiste ? false : true;
                                        },
                                        dependencies: [nomeJaExiste]
                                    }}
                                    invalidText="Nome de usuário já existe"
                                    value={usuario.nome}
                                    onDebounce={async (newValue) => {
                                        await verificaNomeUsuario(newValue);
                                        setLoadingNomeUsuario(false);
                                    }}
                                    debounceTime={1000}
                                    onChange={(newValue) => {
                                        setLoadingNomeUsuario(true);
                                        setUsuario({
                                            ...usuario,
                                            nome: newValue,
                                        });
                                    }}
                                    placeholder="Nome"
                                    maxLength={16}
                                />
                            )}

                            <div
                                className={
                                    usuario.novo ? "alterar novo" : "alterar"
                                }
                            >
                                <Input
                                    label="E-mail"
                                    sizeLabel="medium"
                                    type="text"
                                    name="email"
                                    required={true}
                                    isValid={{
                                        isValidFunction: validateEmail,
                                        dependencies: [usuario.email_notificacao],
                                    }}
                                    value={usuario.email_notificacao}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            email_notificacao: newValue,
                                        });
                                    }}
                                    placeholder="E-mail"
                                    disabled={!usuario.novo ? true : false}
                                />
                                {!usuario.novo ? (
                                    <div
                                        className="botao_alterar"
                                        onClick={() => {
                                            setPhoneBox(false);
                                            setPassBox(false);
                                            setMailBox(true);
                                            setPlanBox(false);
                                        }}
                                    >
                                        Alterar
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={
                                    usuario.novo ? "alterar novo" : "alterar"
                                }
                            >
                                <Input
                                    label="Telefone"
                                    sizeLabel="medium"
                                    type="text"
                                    name="Telefone"
                                    required={true}
                                    mask={{
                                        mask: phoneMask,
                                        unmask: unmaskToNumbers,
                                        maskValidation: validatePhone
                                    }}
                                    isValid={{
                                        isValidFunction: validatePhone,
                                        dependencies: [usuario.telefone],
                                    }}
                                    value={usuario.telefone}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            telefone: newValue,
                                        });
                                    }}
                                    placeholder="Telefone"
              
                                    disabled={!usuario.novo ? true : false}
                                />
                                {!usuario.novo ? (
                                    <div
                                        className="botao_alterar"
                                        onClick={() => {
                                            setMailBox(false);
                                            setPassBox(false);
                                            setPhoneBox(true);
                                            setPlanBox(false);
                                        }}
                                    >
                                        Alterar
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={
                                    usuario.novo ? "alterar novo" : "alterar"
                                }
                            >
                                <Input
                                    label="Senha"
                                    sizeLabel="medium"
                                    type="password"
                                    name="senha"
                                    value={usuario.senha}
                                    required={true}
                                    minLength={6}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            senha: newValue,
                                        });
                                    }}
                                    placeholder={"******"}
                                    error=""
                                    disabled={!usuario.novo ? true : false}
                                />
                                {!usuario.novo ? (
                                    <div
                                        className="botao_alterar"
                                        onClick={() => {
                                            setMailBox(false);
                                            setPhoneBox(false);
                                            setPassBox(true);
                                            setPlanBox(false);
                                        }}
                                    >
                                        Alterar
                                    </div>
                                ) : null}
                            </div>
                            <Select
                                label="Permissão"
                                sizeLabel="medium"
                                name="permissão"
                                required={true}
                                infoModal={handleInfoModal}
                                value={usuario.tipo}
                                onChange={(newValue) => {
                                    setUsuario({ ...usuario, tipo: newValue });
                                }}
                                placeholder="Permissão"
                                options={
                                    (usuariosNomes.length > 1 ||
                                        usuario.novo) &&
                                    MeusUsuarios[0].tipo == "1" &&
                                    MeusUsuarios[0].nome != usuario.nome
                                        ? [
                                              {
                                                  name: "Gerente",
                                                  value: "1",
                                              },
                                              {
                                                  name: "Vendedor Interno",
                                                  value: "2",
                                              },
                                              {
                                                  name: "Vendedor Externo",
                                                  value: "4",
                                              },
                                          ]
                                        : [
                                              {
                                                  name: "Gerente",
                                                  value: "1",
                                              },
                                          ]
                                }
                            />
                            <div className="botoes_">
                                <div
                                    className="novo_usuario"
                                    onClick={() => {
                                        if (!limiteLicencaAtingido) {
                                            if (usuario.novo) {
                                                setUsuario(MeusUsuarios[0]);
                                            } else {
                                                handleNovoUsuario();
                                            }
                                        } else {
                                            setAvisoLimiteAtingido(true);
                                        }
                                    }}
                                >
                                    {!usuario.novo
                                        ? "Adicionar novo usuário"
                                        : "Editar usuários"}
                                </div>
                                <Button
                                    title="Salvar alterações"
                                    className={`botao_alterar ${salvarHabilitado ? "" : "disabled"}`}
                                    width="50%"
                                    fontSize="2.5rem"
                                    padding="0"
                                    type="submit"
                                    loading={loadingNomeUsuario}
                                />
                            </div>
                        </div>
                        <div className="linha_abaixo"></div>
                        <div className="titulo_assinatura">Assinatura</div>

                        <div className="alterar">
                            <Input
                                label="Plano contratado"
                                sizeLabel="medium"
                                type="text"
                                name="nome"
                                value={periodicidade.periodicidade}
                                onChange={(newValue) => {
                                    setPeriodicidade({
                                        ...periodicidade,
                                        periodicidade: newValue,
                                    });
                                }}
                                placeholder={"Plano"}
                                error=""
                                disabled={true}
                            />
                            <div
                                className="botao_alterar"
                                onClick={() => {
                                    setPhoneBox(false);
                                    setPassBox(false);
                                    setMailBox(false);
                                    setPlanBox(true);
                                }}
                            >
                                Alterar
                            </div>
                        </div>

                        <div className="titulo_input">
                            Periodicidade: {periodicidade.data_inicio} até{" "}
                            {periodicidade.data_expiracao}
                        </div>
                    </Form>
                )}
            </LeftRight>
            <Middle theme={theme}>
                <div></div>
            </Middle>
            <LeftRight theme={theme}>
                {window.innerWidth > 1024 ? (
                    <>
                        <SlidingBox
                            active={mailBox}
                            direction="right"
                        >
                            <MailBox
                                setMailBox={setMailBox}
                                onChange={(newValue) => {
                                    setUsuario({
                                        ...usuario,
                                        email_notificacao: newValue,
                                    });
                                }}
                            />
                        </SlidingBox>
                        <SlidingBox
                            active={phoneBox}
                            direction="right"
                        >
                            <PhoneBox
                                setPhoneBox={setPhoneBox}
                                actualPhone={usuario.telefone}
                                onChange={(newValue) => {
                                    setUsuario({
                                        ...usuario,
                                        telefone: newValue,
                                    });
                                }}
                            />
                        </SlidingBox>
                        <SlidingBox
                            active={passBox}
                            direction="right"
                        >
                            <PassBox
                                setPassBox={setPassBox}
                                onChange={(newValue) => {
                                    setUsuario({ ...usuario, senha: newValue });
                                }}
                            />
                        </SlidingBox>
                        <SlidingBox
                            active={planBox}
                            direction="right"
                        >
                            <PlanBox
                                planoAtual={periodicidade.periodicidade}
                                setPlanBox={setPlanBox}
                            />
                        </SlidingBox>
                    </>
                ) : (
                    <>
                        <Modal
                            hideDesktop
                            isOpen={mailBox}
                            setModal={setMailBox}
                            children={
                                <MailBox
                                    setMailBox={setMailBox}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            email_notificacao: newValue,
                                        });
                                    }}
                                />
                            }
                            width="90%"
                            align="center"
                            justify="center"
                            padding="30px"
                            animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                            margin="0rem 2rem 0rem 2rem"
                        />
                        <Modal
                            hideDesktop
                            isOpen={phoneBox}
                            setModal={setPhoneBox}
                            children={
                                <PhoneBox
                                    setPhoneBox={setPhoneBox}
                                    actualPhone={usuario.telefone}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            telefone: newValue,
                                        });
                                    }}
                                />
                            }
                            width="90%"
                            align="center"
                            justify="center"
                            padding="30px"
                            animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                            margin="0rem 2rem 0rem 2rem"
                        />
                        <Modal
                            hideDesktop
                            isOpen={passBox}
                            setModal={setPassBox}
                            children={
                                <PassBox
                                    setPassBox={setPassBox}
                                    onChange={(newValue) => {
                                        setUsuario({
                                            ...usuario,
                                            senha: newValue,
                                        });
                                    }}
                                />
                            }
                            width="90%"
                            align="center"
                            justify="center"
                            padding="30px"
                            animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                            margin="0rem 2rem 0rem 2rem"
                        />
                        <Modal
                            hideDesktop
                            isOpen={planBox}
                            setModal={setPlanBox}
                            children={
                                <PlanBox
                                    planoAtual={periodicidade.periodicidade}
                                    setPlanBox={setPlanBox}
                                />
                            }
                            width="90%"
                            align="center"
                            justify="center"
                            animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                            padding="3rem"
                            margin="0rem 2rem 0rem 2rem"
                        />
                    </>
                )}
            </LeftRight>
            <Modal
                isOpen={avisoLimiteAtingido}
                setModal={setAvisoLimiteAtingido}
                children={
                    <div className="aviso_limite_atingido">
                        <Titulo
                            justifyContent="center"
                            flexDirection="column-reverse"
                            titulo="Limite de usuários atingido"
                            subtitulo={`  Você atingiu o limite de usuários permitidos para o seu plano. Para adicionar mais usuários, entre em contato com o nosso suporte.`}
                        />
                        <div
                            className="botoes"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-around",
                            }}
                        >
                            <div
                                className="cancelar"
                                style={{
                                    color: theme.colors.brandPrimary,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setAvisoLimiteAtingido(false);
                                }}
                            >
                                Cancelar
                            </div>
                            <Button
                                title="Falar com atendimento"
                                className="botao_ok"
                                width="50%"
                                padding="0"
                                onClick={() => {
                                    const url = `https://api.whatsapp.com/send?phone=555434434563&text=
                            Olá, gostaria de adicionar mais usuários na minha conta.`;
                                    window.open(url, "_blank");
                                }}
                            />
                        </div>
                    </div>
                }
                width="100rem"
                maxWidth="99%"
                align="center"
                justify="center"
                animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                padding="30px"
                margin="0rem 2rem 0rem 2rem"
            />
            <Modal
                isOpen={modalAtualizando}
                setModal={() => modalAtualizando}
                children={
                    <ModalSucesso message={mensagemModal} type={stateModal} />
                }
                width="35vw"
                align="center"
                justify="center"
                animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                margin="0rem 2rem 0rem 2rem"
            />
            <Modal
                isOpen={modalPermissao}
                setModal={setmodalPermissao}
                children={
                    <>
                        <Titulo
                            justifyContent="center"
                            flexDirection="column-reverse"
                            titulo="Permissões de usuários"
                            subtitulo={``}
                        />
                        <ViewModalPermissoes>
                            <div className="titulo">Gerente</div>
                            <div className="texto">
                                Possui permissão para criação de catálogos,
                                cadastro/edição de produtos, gerenciamento de
                                novos usuários (vendedores) e visualização de
                                todos os pedidos de todos os vendedores.
                            </div>
                            <div className="titulo">Vendedor Interno</div>
                            <div className="texto">
                                Possui permissão para criação de catálogos,
                                visualização de seus pedidos e cadastro/edição
                                de produtos.
                            </div>
                            <div className="titulo">Vendedor Externo</div>
                            <div className="texto">
                                Possui permissão para criação de catálogos e
                                visualização de seus pedidos.
                            </div>
                        </ViewModalPermissoes>
                        <div
                            className="botoes"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                title="Fechar"
                                className="botao_ok"
                                width="50%"
                                padding="0"
                                onClick={() => {
                                    setmodalPermissao(false);
                                }}
                            />
                        </div>
                    </>
                }
                width={window.innerWidth > 762 ? "50%" : "95%"}
                align="center"
                justify="center"
                animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
                padding="30px"
                margin="0rem 2rem 0rem 2rem"
            />
        </ViewPerfil>
    );
};
