import React, { useContext, useEffect, useState } from "react";
import { ContainerModalChaveIntegracao, MainContainerModalChaveIntegracao } from "./styles";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";
import { ReactComponent as Arrow } from "../../../../assets/newImages/icons/arrowLeft.svg";
import { Button } from "../../../Button";
import { ToggleConfigurar } from "../../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { EmpresaApi } from "../../../../hooks/empresaApi";
import Titulo from "../../../Titulo/Titulo";
import { Link } from "react-router-dom";
import { ConfiguracoesContext } from "../../../../contexts/Configuracoes/ConfiguracoesContext";
import { PerfilApi } from "../../../../hooks/perfilApi";
import LoadingComFundo from "../../../Loading/LoadingComFundo/LoadingComFundo";
import { EmpresaContext } from "../../../../contexts/Empresa/EmpresaContext";

interface ModalChaveIntegracaoProps {
  setModalOpen: (newValue: boolean) => void;
  onChange: (newValue: {
    chave: string;
    cadastrarProdutos: boolean;
    cadastrarImagens: boolean;
  }) => void;
}

const ModalChaveIntegracao: React.FC<ModalChaveIntegracaoProps> = ({
  setModalOpen,
  onChange,
}) => {
  const empresaApi = EmpresaApi();
  const { theme } = useTheme();
  const [etapa, setEtapa] = useState<number>(1);
  const {empresa} = useContext(EmpresaContext)
  const [configuracoes, setConfiguracoes] = useState<{
    cadastrarProdutos: boolean;
    cadastrarImagens: boolean;
  }>({
    cadastrarProdutos: false,
    cadastrarImagens: false,
  });
  const perfilApi = PerfilApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [limiteLicencas, setLimiteLicencas] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      setLimiteLicencas(await perfilApi.verificaLimiteLicenca());
      setLoading(false);
    };
    init();
  }, []);

  const handleCriarChave = async () => {
    empresaApi
      .gerar_chave_api(configuracoes.cadastrarProdutos, configuracoes.cadastrarImagens)
      .then((response) => {
        if (response) {
          onChange({
            chave: response,
            cadastrarProdutos: configuracoes.cadastrarProdutos,
            cadastrarImagens: configuracoes.cadastrarImagens,
          });
          setModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainerModalChaveIntegracao>
      {loading ? (
        <LoadingComFundo />
      ) : (
        <>
          <Titulo
            title="Meus produtos / Adicionar através do ERP"
            subtitle="Gerar chave via API"
          />
          {etapa === 1 && (
            <ContainerModalChaveIntegracao theme={theme}>
              <div className="texto">
                Ao gerar a chave será criado um novo usuário de integração na sua
                conta, gerando custos à sua mensalidade.
              </div>
              <div className="row-flex">
                <div
                  className="arrow"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  <Arrow />
                  Voltar
                </div>
                {limiteLicencas !== 'LIMITE_USUARIOS' ? (
                  <Button
                    onClick={() => {
                      setEtapa(2);
                    }}
                    className="btn-salvar"
                    title={`${'Concordar e continuar'}`}
                    width="fit-content"
                  />
                ) : (
                  <Button 
                    title="Falar com o atendimento"
                    onClick={() => {
                      window.open(`https://api.whatsapp.com/send?phone=555434434563&text= Gostaria de gerar a chave da API.+Meu+usu%C3%A1rio+%C3%A9:+${empresa?.usuario}+da+empresa:+${empresa?.empresa}.`, "_blank")
                    }}
                  />
                )}
              </div>
            </ContainerModalChaveIntegracao>
          )}
          {etapa === 2 && (
            <ContainerModalChaveIntegracao theme={theme}>
              <div className="texto">
                Através da chave de API listagem dos pedidos é apresentada em seu
                sistema. Você pode também cadastrar seus produtos e suas imagens,
                habilitando as opções abaixo:
              </div>
              <div className="configs">
                <label className="config">
                  <ToggleConfigurar
                    onChange={(newValue) => {
                      setConfiguracoes({ ...configuracoes, cadastrarProdutos: newValue });
                    }}
                    value={configuracoes.cadastrarProdutos}
                  />
                  <div className="title">Produtos</div>
                </label>
                <label className="config">
                  <ToggleConfigurar
                    onChange={(newValue) => {
                      setConfiguracoes({ ...configuracoes, cadastrarImagens: newValue });
                    }}
                    value={configuracoes.cadastrarImagens}
                  />
                  <div className="title">Imagens</div>
                </label>
              </div>
              <div className="row-flex">
                <div
                  className="arrow"
                  onClick={() => {
                    setEtapa(1);
                  }}
                >
                  <Arrow />
                  Voltar
                </div>
                <Button
                  onClick={() => {
                    handleCriarChave();
                  }}
                  className="btn-salvar"
                  title={"Gerar chave"}
                  width="fit-content"
                />
              </div>
            </ContainerModalChaveIntegracao>
          )}
        </>
      )}
    </MainContainerModalChaveIntegracao>
  );
};

export default ModalChaveIntegracao;
