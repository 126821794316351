import styled from "styled-components";
import theme from "../../Themes/light";

const ContainerPrintableSacola = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    @page {
      margin: 35px 40px;
    }
    page-break-after: always;
    width: 100%;
    .container {
      position: relative;
      width: 100%;
      color: ${theme.colors.brandSecondary} !important;
      padding: 0 20px;
      .flex {
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        color: inherit !important;
        .titulo {
          font-size: 18px;
          font-weight: bold;
          color: inherit !important;
        }
        span {
          font-size: 11px;
          font-weight: bold;
          color: inherit !important;
        }
        div {
          color: inherit !important;
        }
      }
      .resumo {
        padding: 20px 0 20px 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        text-justify: right;
        gap: 20px;
        div {
          font-size: 11px;
          color: ${theme.colors.brandSecondary} !important;
          font-weight: bold;
        }
      }
    }
    .container-linha {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15%;
    }
    .linha {
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.placeholder} !important;
      border-radius: 50px;
    }
    table {
      width: 100%;
      thead {
        th {
          color: ${theme.colors.brandSecondary} !important;
          text-align: center;
          &:first-of-type {
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          position: relative;
          td {
            color: ${theme.colors.brandSecondary} !important;
            text-align: center;
            padding: 4px 2px;
            &:first-of-type {
              text-align: left;
            }
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: ${theme.colors.placeholder} !important;
            border-radius: 50px;
            bottom: 0;
            left: 0;
          }
        }
      }
      .flex-produto {
        width: 100%;
        display: grid;
        grid-template-columns: 35px auto;
        gap: 2rem;
        align-items: start;
        justify-items: start;
        text-align: left;
        font-size: 12px;
        div {
          color: ${theme.colors.brandSecondary} !important;
        }
        .produto-imagem {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
  }
`;

export { ContainerPrintableSacola };
