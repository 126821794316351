import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const ImportacaoMainContainer = styled.div<{theme: ThemeType}>`
    padding: 6rem 10rem 20rem 10rem;
    .form-tabela-de-preco{
        display: contents;
        .grid-container{
            margin: 5rem 0;
            padding: 5rem 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10rem;
            position: relative;
            .sliding-wrapper{
                width: 100%;
                position: relative;
                overflow: hidden;
                .sliding-container{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    transition: all 0.3s ease-in-out;
                }
                .sliding-active{
                    left: 0;
                }
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                right: calc(50% - 1px);
                bottom: 0;
                width: 2px;
                border-radius: 50px;
                background-color: ${({theme}) => theme.colors.placeholder};
            }
        }
        .footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 3rem;
            flex-wrap: wrap;
            .remover-produtos {
                color: ${({ theme }) => theme.colors.brandSecondary};
                background-color: ${({ theme }) => theme.colors.elements};
                cursor: pointer;
                transition: color 0.2s ease-in-out;
                white-space: nowrap;
            }
            .baixar-csv {
                color: ${({ theme }) => theme.colors.brandSecondary};
                background-color: ${({ theme }) => theme.colors.elements};
                cursor: pointer;
                transition: color 0.2s ease-in-out;
                white-space: nowrap;
            }
            .botoes-container {
                display: flex;
                gap: 3rem;
                align-items: center;
                justify-content: space-between;
                white-space: nowrap;
                .button-avancar{
                    width: 35rem;
                }
                span {
                    cursor: pointer;
                }
                .arrow {
                    stroke: ${({ theme }) => theme.colors.brandSecondary};
                }
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 3rem 5rem 30rem 5rem;
        .form-tabela-de-preco{
            .grid-container{
                display: flex;
                flex-direction: column;
                gap: 5rem;
                &::after{
                    display: none;
                }
                .sliding-wrapper{
                    .sliding-container{
                        position: relative;
                    }
                }
            }
            .footer{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                button{
                    width: 100%;
                }
                .botoes-container {
                    display: contents;
                    .button-avancar{
                        width: 100%;
                    }
                }
            }
        }
    }
`;

export {
    ImportacaoMainContainer
};

