import React, { useContext, useEffect, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { currencyMask } from "../../../services/Masks";
import { unmaskToNumbers } from "../../../services/Unmasks";
import { FreteGratis as FreteGratisType } from "../../../types/FreteGratis";
import { Input } from "../../Input";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ContainerFreteGratis } from "./styles";

interface FreteGratisProps {
  onConfigurarPorEstado: (index) => void;
  onExclusaoFreteGratis: (lenght) => void;
  index: number;
  configIn: "empresa" | "configuracoes";
}

const FreteGratis: React.FC<FreteGratisProps> = ({
  onConfigurarPorEstado,
  onExclusaoFreteGratis,
  configIn,
  index,
}) => {
  const { configuracoes, fretes_gratis, setFretes_gratis } =
    useContext(ConfiguracoesContext);

  const [indexFrete, setIndexFrete] = useState<number>(index);

  const { theme } = useTheme();

  useEffect(() => {
    if (index === -1) {
      const newFreteGratis: FreteGratisType[] = [...fretes_gratis];
      newFreteGratis.push({
        ativo: true,
        valor_minimo: "0",
        brasil_inteiro: true,
        por_estado: {
          ativo: false,
          estados: [],
        },
      });
      setFretes_gratis(newFreteGratis);
      setIndexFrete(newFreteGratis.length - 1);
    } else {
      if (
        fretes_gratis[indexFrete].por_estado.estados.length == 27 ||
        fretes_gratis[indexFrete].por_estado.estados.length == 0
      ) {
        const copia = [...fretes_gratis];
        copia[indexFrete].por_estado.ativo = false;
        copia[indexFrete].brasil_inteiro = true;
        setFretes_gratis(copia);
      }
    }
  }, [index]);

  return (
    <>
      <ContainerFreteGratis configIn={configIn} theme={theme}>
        {fretes_gratis.length > 0 && fretes_gratis[indexFrete] && (
          <div className="containerFreteGratis">
            {configIn !== "configuracoes" && (
              <Titulo
                titulo="Frete Grátis"
                subtitulo={"Configure os dados do seu frete grátis"}
                flexDirection="column-reverse"
              />
            )}
            <div className="inputContainer">
              <Input
                type={"text"}
                mask={{
                  mask: currencyMask,
                  unmask: unmaskToNumbers,
                }}
                value={
                  fretes_gratis[indexFrete].valor_minimo !== "" &&
                  Number.parseFloat(fretes_gratis[indexFrete].valor_minimo) > 0
                    ? fretes_gratis[indexFrete].valor_minimo.toString()
                    : "0"
                }
                placeholder={"R$100,00"}
                required={true}
                ignoreValidations={!configuracoes.frete.freteGratisAtivo}
                sizeLabel="medium"
                onChange={(newValue) => {
                  const copia = [...fretes_gratis];
                  copia[indexFrete].valor_minimo = newValue;
                  setFretes_gratis(copia);
                }}
                label="Valor mínimo da sacola"
              ></Input>
            </div>
            <div className="containerToggles">
              <div className="containerTogglesInner">
                <ToggleConfigurar
                  nome={"Brasil inteiro"}
                  required={
                    !fretes_gratis[indexFrete].por_estado.ativo &&
                    !fretes_gratis[indexFrete].brasil_inteiro
                  }
                  value={fretes_gratis[indexFrete].brasil_inteiro}
                  onChange={(newValue) => {
                    const copia = [...fretes_gratis];
                    copia[indexFrete].brasil_inteiro = newValue;
                    copia[indexFrete].por_estado.ativo = false;
                    if (newValue) copia[indexFrete].por_estado.estados = [];
                    setFretes_gratis(copia);
                  }}
                ></ToggleConfigurar>
                <ToggleConfigurar
                  nome={"Por estado"}
                  value={fretes_gratis[indexFrete].por_estado.ativo}
                  isValid={
                    !(
                      fretes_gratis[indexFrete].por_estado.estados.length ==
                        0 && fretes_gratis[indexFrete].por_estado.ativo
                    )
                  }
                  ignoreValidations={!configuracoes.frete.freteGratisAtivo}
                  invalidText="Selecione pelo menos um estado"
                  onChange={(newValue) => {
                    const copia = [...fretes_gratis];
                    copia[indexFrete].por_estado.ativo = newValue;
                    copia[indexFrete].brasil_inteiro = false;
                    setFretes_gratis(copia);
                  }}
                  onConfigurar={(e) => {
                    e.stopPropagation();
                    onConfigurarPorEstado(indexFrete);
                  }}
                ></ToggleConfigurar>
              </div>
            </div>
            <div
              className="exclusao"
              onClick={() => {
                const newFretes_gratis = [...fretes_gratis];
                newFretes_gratis.splice(indexFrete, 1);
                setFretes_gratis(newFretes_gratis);

                onExclusaoFreteGratis(newFretes_gratis.length);
              }}
            >
              Excluir regra de frete grátis
            </div>
          </div>
        )}
      </ContainerFreteGratis>
    </>
  );
};
export default FreteGratis;
