import styled, { css } from "styled-components";

const commonStyles = css`
  display: contents;
  .containerEntregaLocal {
    width: 100rem;
    max-width: 90vw;
    .dropdown {
      display: contents;
    }
    .cepContainer {
      font-size: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      position: relative;
      padding: 0 0 5rem 0;
      > .containerInput {
        width: 21rem;
        margin-right: 10px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 35rem;
        height: 2px;
        border-radius: 50px;
        background-color: ${(props) => props.theme.colors.elements};
        bottom: 0;
      }
    }
    .togglesContainer {
      display: flex;
      padding: 3rem 0 3rem 0;
      flex-direction: column;
      align-items: center;
      .togglesInnerContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .containerToggleConfigurar {
          .nome {
            color: ${(props) => props.theme.colors.brandSecondary};
          }
        }
      }
    }
    .inputContainer {
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      position: relative;
      padding: 2rem 10rem 3rem 10rem;
    }
  }
`;

export const ContainerEntregaLocal = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes"
      ? css`
          .containerEntregaLocal {
            width: 100%;
            .dropdown {
              display: block;
              .cepContainer {
                color: ${(props) => props.theme.colors.brandSecondary};
                padding-top: 3rem;
                display: block;
                text-align: left;
                .containerInput {
                  width: 100%;
                  margin: 0;
                }
                &::after {
                  display: none;
                }
              }
              .togglesContainer {
                padding: 0;
                display: block;
                gap: 4rem;
                .togglesInnerContainer {
                  display: contents;
                  .containerToggleConfigurar {
                    .nome {
                      width: auto;
                      margin-right: 2rem;
                    }
                  }
                }
              }
            }
          }
        `
      : null}
`;
