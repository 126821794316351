import React from "react";
import { useEffect } from "react";
import { Button } from "../Button";
import moment from "moment";
import { Input } from "../Input";
import { FiltrosDataContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ReactComponent as CalenderSVG } from "../../assets/newImages/icons/calender.svg";

interface valueProps {
  inicio: string;
  fim: string;
  placeholder:
    | "do dia"
    | "do mes"
    | "últimos 30 dias"
    | "intervalo personalizado"
    | string;
}
interface filtrosDataProps {
  onAplicar: (newValue: valueProps) => void;
  value: valueProps;
}

export const FiltrosData: React.FC<filtrosDataProps> = ({
  onAplicar,
  value,
}) => {
  const { theme } = useTheme();

  const [dropdown, setDropdown] = React.useState(false);
  const [dataInicio, setDataInicio] = React.useState(
    moment().format("YYYY-MM-DD"),
  );
  const [dataFim, setDataFim] = React.useState(moment().format("YYYY-MM-DD"));

  const values = [
    {
      placeholder: "Do dia",
      value: () => {
        return {
          inicio: `${moment().format("YYYY-MM-DD")} 00:00:00`,
          fim: `${moment().format("YYYY-MM-DD")} 23:59:59`,
          placeholder: "Do dia",
        };
      },
    },
    {
      placeholder: "Do mês",
      value: () => {
        return {
          inicio: `${moment().startOf("month").format("YYYY-MM-DD")} 00:00:00`,
          fim: `${moment().format("YYYY-MM-DD")} 23:59:59`,
          placeholder: "Do mês",
        };
      },
    },
    {
      placeholder: "Últimos 30 dias",
      value: () => {
        return {
          inicio: `${moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD")} 00:00:00`,
          fim: `${moment().format("YYYY-MM-DD")} 23:59:59`,
          placeholder: "Últimos 30 dias",
        };
      },
    },
    {
      placeholder: "Intervalo personalizado",
      value: (dataInicio, dataFim) => {
        return {
          inicio: `${dataInicio} 00:00:00`,
          fim: `${dataFim} 23:59:59`,
          placeholder: "Intervalo personalizado",
        };
      },
    },
  ];
  const [placeholder, setPlaceholder] = React.useState(values[2].placeholder);
  const [selecionado, setSelecionado] = React.useState(values[2]);

  const handleCancelar = () => {
    setDropdown(false);
    setSelecionado(
      values[values.findIndex((value) => value.placeholder === placeholder)],
    );
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDropdown(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    value.inicio && setDataInicio(moment(value.inicio).format("YYYY-MM-DD"));
    value.fim && setDataFim(moment(value.fim).format("YYYY-MM-DD"));
    if (value.placeholder) {
      setPlaceholder(value.placeholder);
      setSelecionado(
        values[values.findIndex((value) => value.placeholder === placeholder)],
      );
    }
  }, []);

  return (
    <>
      <FiltrosDataContainer theme={theme}>
        <div
          className="filtrosData"
          onClick={() => {
            setDropdown(true);
          }}
        >
          <CalenderSVG />
          <div>{placeholder}</div>
        </div>
        {dropdown && (
          <>
            <div className="fundoDropdown" onClick={handleCancelar}></div>
            <div className="containerDropdown">
              <div className="dropdown">
                <div className="filtros">
                  {values.map((item, index) => (
                    <div
                      key={index}
                      className={`filtro ${
                        selecionado.placeholder && selecionado.placeholder === item.placeholder
                          ? "selecionado"
                          : ""
                      }`}
                      onClick={() => {
                        setSelecionado(item);
                      }}
                    >
                      {item.placeholder}
                    </div>
                  ))}
                </div>
                <div
                  className={`dates-picker ${
                    selecionado.placeholder && selecionado.placeholder === "Intervalo personalizado" &&
                    "ativo"
                  }`}
                >
                  <label>Selecione um intervalo de tempo</label>
                  <div className="datas">
                    <Input
                      type={"date"}
                      label="Data de início"
                      value={dataInicio}
                      placeholder={"01/01/2023"}
                      max={"9999-12-31"}
                      onChange={(newValue) => {
                        setDataInicio(newValue);
                        if (moment(newValue).isAfter(moment(dataFim))) {
                          setDataFim(newValue);
                        }
                      }}
                    ></Input>
                    <Input
                      type={"date"}
                      label="Data final"
                      value={dataFim}
                      placeholder={"22/01/2023"}
                      max={"9999-12-31"}
                      onChange={(newValue) => {
                        setDataFim(newValue);
                        if (moment(newValue).isBefore(moment(dataInicio))) {
                          setDataInicio(newValue);
                        }
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="aplicarCancelar">
                  <Button
                    onClick={() => {
                      setPlaceholder(selecionado.placeholder ? selecionado.placeholder : "");
                      onAplicar(selecionado.value(dataInicio, dataFim));
                      setDropdown(false);
                    }}
                    title={"Aplicar"}
                  />
                  <div className="cancelar" onClick={handleCancelar}>
                    Cancelar
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </FiltrosDataContainer>
    </>
  );
};
