import React, { useState } from "react";
import Fallback from "../../assets/images/empresas/fallback.jpg";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ImagemZoom } from "../../pages/Pedidos/Pedido/styles";
import { TIPO_MIDIA } from "../../services/Consts";
import { obterMiniaturaVideoYouTube } from "../../services/Functions";
import { ProdutoTable } from "../../types/Produto";
import ImgWithLoading from "../ImgWithLoading/ImgWithLoading";
import Modal from "../Modal/Modal";
import "./styles";
import { TableProdutosContainer } from "./styles";

interface TableProdutosProps {
  produtos: ProdutoTable[];
  moeda?: string;
  template: "principal" | "impressao";
}

const TableProdutos: React.FC<TableProdutosProps> = ({
  produtos,
  template,
  moeda = "R$",
}) => {
  const [modalProdutoFoto, setModalProdutoFoto] = useState(false);
  const [imgSrcZoom, setImgSrcZoom] = useState("");
  const colunasComVariacao = 4;

  const { theme } = useTheme();

  return (
    <>
      <TableProdutosContainer
        className={`${
          template === "principal" ? "scrollBonitoBottom" : ""
        } table-produtos`}
        theme={theme}
        colunasComVariacao={colunasComVariacao}
        template={template}
      >
        <table className="table-grid">
          <thead>
            <tr className="row-simulated">
              <th className="align-left">Produto</th>
              <th className="align-left">Variação</th>
              <th>{template === "principal" ? "Quantidade" : "Qtd"}</th>
              <th>Preço</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto, index) => {
              return (
                <>
                  <tr className="row-simulated">
                    <div
                      className="flex-produto"
                      style={{
                        gridRowStart: `span ${
                          (produto.variacoes?.length || 0) * 2
                        }`,
                      }}
                    >
                      <div className="produto-imagem">
                        <div 
                          className="imagem-produto-container"
                          onClick={() => {
                            setModalProdutoFoto(true);
                            setImgSrcZoom(
                              Number.parseInt(produto.tipo_midia!) ===
                                TIPO_MIDIA.YOUTUBE
                                ? obterMiniaturaVideoYouTube(produto.imagem)
                                : produto.imagem || Fallback
                            );
                          }}
                        >
                          <ImgWithLoading
                            src={
                              Number.parseInt(produto.tipo_midia!) ===
                              TIPO_MIDIA.YOUTUBE
                                ? obterMiniaturaVideoYouTube(produto.imagem)
                                : produto.imagem
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="informacoes-produto">
                        <div>
                          {produto.referencia}{" "}
                          {produto.peso ? `| ${produto.peso}g` : ""}
                        </div>
                        {produto.ean && (
                          <div className="ean">EAN: {produto.ean}</div>
                        )}
                        <div>
                          <b>{produto.nome}</b>
                        </div>
                        <div
                          className="descricao"
                          dangerouslySetInnerHTML={{
                            __html: produto.descricao || "",
                          }}
                        />
                      </div>
                    </div>
                    {produto.variacoes?.map((variacao, index) => {
                      return (
                        <>
                          <div className="flex-variacoes align-left">
                            <span className="align-left">
                              {variacao.variacao}
                            </span>
                          </div>
                          <div className="flex-variacoes">
                            <span>{variacao.quantidade} </span>
                          </div>
                          <div className="flex-variacoes">
                            <span>
                              {variacao.preco === 0 ||
                              variacao.preco === undefined
                                ? template === "principal"
                                  ? "A combinar"
                                  : "-"
                                : `${moeda} ${variacao.preco
                                    .toFixed(2)
                                    .replace(".", ",")}`}
                            </span>
                          </div>
                          <div className="flex-variacoes">
                            <span>
                              {variacao.total === 0 ||
                              variacao.total === undefined
                                ? template === "principal"
                                  ? "A combinar"
                                  : "-"
                                : `${moeda} ${variacao.total
                                    .toFixed(2)
                                    .replace(".", ",")} `}
                            </span>
                          </div>
                          <div className="observacao">
                            {index !== produto.variacoes!.length - 1 && (
                              <div className="separator-line-obs" />
                            )}
                            {variacao.observacao && (
                              <span>
                                <>
                                  <b>Observação: </b>
                                  {variacao.observacao || ""}
                                </>
                              </span>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </tr>
                  <div className="separator-line" />
                </>
              );
            })}
          </tbody>
          <tfoot>
            <div className="flex-footer">
            <div className="info">
              {produtos.reduce((acc, produto) => {
                return (
                  acc +
                  produto.variacoes!.reduce((acc, variacao) => {
                    return acc + produto.peso! * variacao.quantidade;
                  }, 0)
                );
              }, 0) > 0 && (
                <>
                  Peso total:{" "}
                  {`${produtos.reduce((acc, produto) => {
                    return (
                      acc +
                      produto.variacoes!.reduce((acc, variacao) => {
                        return acc + produto.peso! * variacao.quantidade;
                      }, 0)
                    );
                  }, 0)}`}
                </>
              )}
              </div>
              <div className="info">
                Total de produtos:{" "}
                {produtos.reduce((acc, produto) => {
                  return (
                    acc +
                    produto.variacoes!.reduce((acc, variacao) => {
                      return acc + variacao.quantidade;
                    }, 0)
                  );
                }, 0)}
              </div>
              <div className="info">
                Valor dos produtos:{" "}
                {`${moeda} ${produtos
                  .reduce((acc, produto) => {
                    return (
                      acc +
                      produto.variacoes!.reduce((acc, variacao) => {
                        return acc + variacao.total!;
                      }, 0)
                    );
                  }, 0)
                  .toFixed(2)
                  .replace(".", ",")}`}
              </div>
            </div>
          </tfoot>
        </table>
      </TableProdutosContainer>
      <Modal
        isOpen={modalProdutoFoto}
        setModal={setModalProdutoFoto}
        width="100rem"
        maxWidth="99vw"
        align="center"
        justify="center"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      >
        <ImagemZoom onClick={() => setModalProdutoFoto(false)}>
          <ImgWithLoading 
            fullSize={true}
            src={imgSrcZoom} 
            alt=""
          />
        </ImagemZoom>
      </Modal>
    </>
  );
};

export default TableProdutos;
