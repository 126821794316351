import React, { useContext, useEffect, useState } from "react";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ContainerChaveApi, ContainerConfiguracoes } from "./styles";
import Modal from "../../Modal/Modal";
import ModalChaveIntegracao from "./ModalChaveIntegracao/ModalChaveIntegracao";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Swal from "sweetalert2";
import { boolean } from "../../../services/Functions";

type ChaveIntegracaoProps = {
  chave: string;
  setChaveDeIntegracao: (newValue: string) => void;
};

const ChaveIntegracao: React.FC<ChaveIntegracaoProps> = ({
  chave,
  setChaveDeIntegracao,
}) => {
  const { theme } = useTheme();
  const [chaveApi, setChaveApi] = useState<string>(chave);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [mostrarChave, setMostrarChave] = useState<boolean>(false);
  

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  return (
    <>
      <ContainerChaveApi theme={theme}>
        <div className="bloco-input">
          {chaveApi && (
            <Input
              onChange={(newValue) => {
                setChaveApi(newValue);
              }}
              type={"password"}
              value={chaveApi}
              placeholder="Chave de integração"
              disabled
              allowEye={true}
              label="Chave de API"
            ></Input>
          )}
          {!chaveApi && (
            <div className="pai-botao">
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="btn-salvar"
                title={"Gerar chave"}
                width="fit-content"
              />
            </div>
          )}
        </div>
      </ContainerChaveApi>

      {chaveApi && (
        <ContainerConfiguracoes theme={theme}>
          <div className="texto">
            Através da chave de API uma listagem dos pedidos é apresentada em
            seu sistema. Você pode também cadastrar seus produtos e suas
            imagens, habilitando as opções abaixo:
          </div>
          <label className="config">
            <ToggleConfigurar
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  integracao: {
                    ...configuracoes.integracao,
                    cadastrarProdutos: newValue,
                  },
                });
              }}
              value={configuracoes.integracao.cadastrarProdutos}
            />
            <div className="title">Produtos</div>
          </label>

          <label className="config">
            <ToggleConfigurar
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  integracao: {
                    ...configuracoes.integracao,
                    cadastrarImagens: newValue,
                  },
                });
              }}
              value={configuracoes.integracao.cadastrarImagens}
            />
            <div className="title">Imagens</div>
          </label>
        </ContainerConfiguracoes>
      )}

      <Modal
        isOpen={isModalOpen}
        setModal={setIsModalOpen}
        children={
          <ModalChaveIntegracao
            setModalOpen={setIsModalOpen}
            onChange={(newValue: {
              chave: string;
              cadastrarProdutos: boolean;
              cadastrarImagens: boolean;
            }) => {
              setConfiguracoes({
                ...configuracoes,
                integracao: {
                  ...configuracoes.integracao,
                  cadastrarImagens: newValue.cadastrarImagens,
                  cadastrarProdutos: newValue.cadastrarProdutos,
                },
              });
              setChaveDeIntegracao(newValue.chave);
              setChaveApi(newValue.chave);
              setIsModalOpen(false);
              setMostrarChave(true);
              Swal.fire({
                title: "Sucesso!",
                text: "Chave gerada com sucesso.",
                icon: "success",
                timer: 1250,
                showConfirmButton: false,
              });
            }}
          />
        }
        width="70rem"
        maxWidth="95vw"
        align="center"
        justify="center"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        padding="3rem 6rem"
      />
    </>
  );
};
export default ChaveIntegracao;
