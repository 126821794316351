import React from "react";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { PerfilContext } from "../../../contexts/Perfil/PerfilContext";
import { Titulo, Texto, Erro, InputsContainer, Botoes } from "../box_styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { swallDadosSalvos } from "../../../services/Functions";
import Form from "../../../components/Form";

interface PassBoxProps {
  onChange: (newValue: string) => void;
  setPassBox: (newValue: boolean) => void;
}

interface Validacao {
  erro: string;
  function: (
    senhaAtual: string,
    novaSenha: string,
    confirmarSenha: string,
  ) => boolean | Promise<any>;
  mensagem: string;
  ativo: boolean;
}

export const PassBox: React.FC<PassBoxProps> = ({ setPassBox, onChange }) => {
  const { theme } = useTheme();

  const Perfil = React.useContext(PerfilContext);

  const [formValid, setFormValid] = React.useState<boolean>(false);

  const [senhaAtual, setSenhaAtual] = React.useState<string>("");
  const [checandoSenhaLoading, setChecandoSenhaLoading] = React.useState<boolean>(false);
  const [senhaAtualConfere, setSenhaAtualConfere] = React.useState<boolean>(false);
  const [novaSenha, setNovaSenha] = React.useState<string>("");
  const [confirmarSenha, setConfirmarSenha] = React.useState<string>("");

  const handleChange = (): void => {
    setPassBox(false);
    swallDadosSalvos();
    onChange(novaSenha);
  };

  return (
    <Form className="view-PassBox" id="view-PassBoxForm" setFormValid={setFormValid} onSubmit={handleChange}>
      <Titulo theme={theme}>Alteração da senha</Titulo>
      <Texto theme={theme}>
        Após ser alterada a senha atual esta será usada para acesso a plataforma
        do Conecta Venda.
      </Texto>
      <InputsContainer theme={theme}>
        <Input
          label="Senha atual"
          sizeLabel="medium"
          type="password"
          required={true}
          value={senhaAtual}
          onDebounce={async (newValue) => {
            const confere = await Perfil.confereSenha(newValue);
            setSenhaAtualConfere(confere);
            setChecandoSenhaLoading(false);
          }}
          debounceTime={750}
          isValid={{
            isValidFunction: (senhaAtual: string) => {
              return senhaAtualConfere ? true : false;
            },
            dependencies: [senhaAtualConfere],
          }}
          invalidText="Senha atual incorreta"
          onChange={(newValue) => {
            setSenhaAtual(newValue);
            setChecandoSenhaLoading(true);
          }}
          placeholder="Senha atual"
        />

        <Input
          label="Nova senha"
          sizeLabel="medium"
          type="password"
          value={novaSenha}
          placeholder="******"
          required={true}
          minLength={6}
          onChange={(newValue) => {
            setNovaSenha(newValue);
          }}
          allowEye={true}
        />

        <Input
          label="Confirmar nova senha"
          sizeLabel="medium"
          type="password"
          minLength={6}
          isValid={{
            isValidFunction: (confirmarSenha: string) => {
              return confirmarSenha === novaSenha;
            },
            dependencies: [novaSenha, confirmarSenha],
          }}
          invalidText="As senhas não conferem"
          value={confirmarSenha}
          onChange={(newValue) => {
            setConfirmarSenha(newValue);
          }}
          allowEye={true}
          placeholder="******"
        />
        <Botoes theme={theme}>
          <Button
            title="Alterar"
            className={`botao_alterar ${formValid ? "" : "disabled"}`}
            width="50%"
            type="submit"
            fontSize="2.5rem"
            loading={checandoSenhaLoading}
          />
          <div
            className="cancelar"
            onClick={() => {
              setPassBox(false);
            }}
          >
            Cancelar
          </div>
        </Botoes>
      </InputsContainer>
    </Form>
  );
};
