import React, { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { blingApi } from "../../../hooks/blingApi";
import { Button } from "../../Button";
import { Input } from "../../Input";
import LoadingComFundo from "../../Loading/LoadingComFundo/LoadingComFundo";
import Modal from "../../Modal/Modal";
import SkeletonBling from "../../Skeletons/SkeletonBling/SkeletonBling";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import Depositos from "./Depositos/Depositos";
import { ContainerIntegracaoBlingV2, ModalContainer } from "./styles";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";

const IntegracaoBlingV2: React.FC = ({}) => {
  const { theme } = useTheme();

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const api = blingApi();

  const [modalAutorizacao, setModalAutorizacao] = useState(true);
  const [modalDepositos, setModalDepositos] = useState(false);

  const callBackUrl = 'https://conectavenda.com.br/postback.php?origem=bling&chave=';
  const [autenticado, setAutenticado] = useState(false);
  const [loading, setLoading] = useState(true); 
  const urlAutorizacao = useRef('');

  const verificarAutenticacao = async () => {
    const res = await api.verificarAutenticacao();
    if(res.error){
      if(res.url){
        setAutenticado(false);
        urlAutorizacao.current = res.url;
      } else {
        Swal.fire({
          title: "Erro inesperado ao verificar autenticação!",
          text: res.error,
          icon: "error",
        });
      }
    } else if(res.chave) {
      setAutenticado(true);
      setConfiguracoes({
        ...configuracoes,
        dados_bling_v2: {
          ...configuracoes.dados_bling_v2!,
          chave: res.chave,
        },
      });
      document.removeEventListener('visibilitychange', handleVisibilityChangeRef.current);
    } else {
      setAutenticado(false);
      Swal.fire({
        title: "Erro inesperado ao verificar autenticação!",
        text: "Erro desconhecido",
        icon: "error",
      });
    }
    setLoading(false);
  }
  
  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
      await verificarAutenticacao();
    }
  };

  const handleVisibilityChangeRef = useRef(() => {});
  
  useEffect(() => {
    handleVisibilityChangeRef.current = handleVisibilityChange;

    const init = async () => {
      handleVisibilityChangeRef.current();
    };
    
    init()
    
    document.addEventListener('visibilitychange', handleVisibilityChangeRef.current);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChangeRef.current);
    };
  }, []);

  const authorize = (e) => {
    window.open(urlAutorizacao.current, "_blank");
  };

  const cancelar = () => {
    setModalAutorizacao(false);

    setConfiguracoes({
      ...configuracoes,
      dados_bling_v2: {
        ...configuracoes.dados_bling_v2!,
        ativo: false,
      },
    });
  }

  return (
    <ContainerIntegracaoBlingV2 theme={theme}>
      {(!autenticado && !loading) && (
        <>
          <Modal 
          width={"60rem"}
          maxWidth="98vw"
          isOpen={modalAutorizacao} 
          backdrop={'static'}
          setModal={setModalAutorizacao}>
            <ModalContainer theme={theme}>
              {loading ? (
                <LoadingComFundo />
              ) : (
                <>
                <Titulo
                  titulo="Autorização Bling"
                  subtitulo="Somente a partir do plano mercúrio."
                  flexDirection="column-reverse"
                  />
                  <div className="texto">
                      Para continuar, é necessário autorizar a integração com o Bling. Clique no botão abaixo para ser redirecionado para a página de autorização.
                  </div>
                  <div className="botoes">
                    <div className="cancelar" onClick={cancelar}>
                      Cancelar
                    </div>
                    <Button 
                      title="Autorizar"
                      onClick={authorize}
                    />
                  </div>
                </>
              )}
            </ModalContainer>
          </Modal>
        </>
      )}
      {(loading || !autenticado) ? (
        <SkeletonBling />
      ) : (
        <>
          {autenticado && (
            <>
              <div className="urlContainer">
                <span>URL de callback:</span>
                <div className="url" onClick={() => {
                  navigator.clipboard.writeText(callBackUrl + configuracoes.dados_bling_v2!.chave).then(res => {
                    Swal.fire({
                      title: "URL copiada!",
                      icon: "success",
                      timer: 1500,
                      showConfirmButton: false,
                    })
                  }, (err) => {
                    Swal.fire({
                      title: "Erro ao copiar URL!",
                      text: "Mas você pode copiar manualmente: " + callBackUrl + configuracoes.dados_bling_v2!.chave,
                      icon: "error",
                      
                    })
                  })
                }}>
                  <span>{callBackUrl}</span>
                  <span>{configuracoes.dados_bling_v2!.chave}</span>
                </div>
              </div>
              <label className="config">
                <ToggleConfigurar
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        upload_fotos_conecta: newValue,
                      },
                    });
                  }}
                  value={configuracoes.dados_bling_v2!.upload_fotos_conecta}
                />
                <div className="title">Upload de fotos pelo Conecta Venda</div>
              </label>

              <label className="config">
                <ToggleConfigurar
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        configurar_observacao_conecta: newValue,
                      },
                    });
                  }}
                  value={configuracoes.dados_bling_v2!.configurar_observacao_conecta}
                />
                <div className="title">
                  Configurar no Conecta se o cliente pode inserir observações no produto
                </div>
              </label>

              <label className="config">
                <ToggleConfigurar
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        controle_estoque: newValue,
                      },
                    });
                  }}
                  value={configuracoes.dados_bling_v2!.controle_estoque}
                />
                <div className="title">Controle de estoque</div>
              </label>

              {configuracoes.dados_bling_v2!.controle_estoque && (
                <label className="config" style={{ marginLeft: "3rem" }}>
                  <ToggleConfigurar
                    onChange={(newValue) => {
                      setConfiguracoes({
                        ...configuracoes,
                        dados_bling_v2: {
                          ...configuracoes.dados_bling_v2!,
                          estoque_ilimitado: newValue,
                        },
                      });
                    }}
                    value={configuracoes.dados_bling_v2!.estoque_ilimitado}
                  />
                  <div className="title">
                    Produtos com estoque zerado no Bling serão considerados com estoque
                    ilimitado
                  </div>
                </label>
              )}
              <label className="config">
                <ToggleConfigurar
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        status_pedido_atendido: newValue,
                      },
                    });
                  }}
                  value={configuracoes.dados_bling_v2!.status_pedido_atendido}
                />
                <div className="title">
                  Pedido com status <strong>"Atendido"</strong>
                </div>
              </label>
              <label className="config">
                <ToggleConfigurar
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        descricao_curta: newValue,
                      },
                    });
                  }}
                  value={configuracoes.dados_bling_v2!.descricao_curta}
                />
                <div className="title">
                  Utilizar descrição curta <strong>"Descrição Principal"</strong>
                </div>
              </label>

              <div className="configs">
                <Input
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        codigo_loja_bling: newValue,
                      },
                    });
                  }}
                  type="text"
                  value={configuracoes.dados_bling_v2!.codigo_loja_bling}
                  placeholder="Código da loja API Bling"
                  showMaxLength={false}
                  maxLength={64}
                  required
                  ignoreValidations={!configuracoes.dados_bling_v2!.ativo}
                  label="Código da loja API Bling"
                />
                <Input
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        multiplicador: newValue,
                      },
                    });
                  }}
                  type="number"
                  step={"any"}
                  value={configuracoes.dados_bling_v2!.multiplicador}
                  placeholder="Multiplicador"
                  min={0.01}
                  max={100}
                  label="Multiplicador"
                  ignoreValidations={!configuracoes.dados_bling_v2!.ativo}
                  required
                />
              </div>
              <>
                <Modal
                  width={"60rem"}
                  maxWidth="98vw"
                  isOpen={modalDepositos}
                  setModal={setModalDepositos}
                  backdrop={"static"}
                >
                  <Depositos onClose={() => setModalDepositos(false)}/>
                </Modal>
                <div className="config">
                  <Button title="Configurar depósitos" onClick={() => setModalDepositos(true)}/>
                </div>
              </>
              <div className="obs">
                <br />
                <span>Observações:</span> <br />
                - Produtos que não possuem o Código (SKU) não serão importados no Conecta Venda. <br />
                - Produtos que não possuem Categoria (Grupo) serão colocados na categoria com o nome de 'Sem Categoria' no Conecta Venda. <br />
                - Ao excluir um produto no Bling, será necessário excluir manualmente no Conecta Venda (para exclusão de variações, a exclusão é feita automaticamente no Conecta Venda).
              </div>
            </>  
          )}
        </>
      )}
    </ContainerIntegracaoBlingV2>
  );
};
export default IntegracaoBlingV2;
