import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { produtosApi } from "../../hooks/produtosApi";
import { boolean, cleanToFilter } from '../../services/Functions';
import { CatalogoUnico } from "../../types/Catalogo";
import { Categoria } from "../../types/Categoria";
import { FiltrosProdutos, Produto } from "../../types/Produto";
import { CatalogoContext } from "../Catalogo/CatalogoContext";
import { ProdutosContext } from "./ProdutosContext";

interface ProdutosProviderProps {
  children: JSX.Element;
  tipoFiltro: "listagem-padrao" | "listagem-catalogo";
}

export const ProdutosProvider: React.FC<ProdutosProviderProps> = ({
  children,
  tipoFiltro,
}) => {
  const apiProdutos = produtosApi();
  const context = useContext(CatalogoContext);
  const catalogoContext = tipoFiltro === "listagem-catalogo" ? context : null;
  const [catalogo, setCatalogo] = useState<CatalogoUnico | null>(catalogoContext?.catalogo || null);
  const [maiorMenorPreco, setMaiorMenorPreco] = useState<number[]>([0, 100]);

  const [loading, setLoading] = useState(false);
  const [newProductModal, setNewProductModal] = useState(false);

  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState<Produto[]>([]);

  // Função para salvar os filtros e filtroPesquisaAux no localStorage
  const saveToLocalStorage = (
    filtros: FiltrosProdutos,
    filtroPesquisaAux: string
  ) => {
    const dataToSave = { filtros, filtroPesquisaAux };
    localStorage.setItem("produtosSettings", JSON.stringify(dataToSave));
  };

  // Função para recuperar os filtros e filtroPesquisaAux do localStorage
  const getFromLocalStorage = () => {
    const storedData = localStorage.getItem("produtosSettings");
    return storedData ? JSON.parse(storedData) : null;
  };

  // Estado inicial dos filtros e filtroPesquisaAux
  const localStorageData = getFromLocalStorage();

  const [filtros, setFiltros] = useState<FiltrosProdutos>(() => {
    return (
      localStorageData?.filtros || {
        filtroPesquisa: "",
        filtroCategoria: "0",
        filtroAtivos: "1",
        filtroEstoque: "todos",
        filtroVisualizacao: "todos",
        filtroOrdenacao: "referencia-asc",
        filtroPrecoMin: 0,
        filtroPrecoMax: 100,
      }
    );
  });

  const [filtroPesquisaAux, setFiltroPesquisaAux] = useState<string>(() => {
    return localStorageData?.filtroPesquisaAux || "";
  });

  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const optionsAtivos = [
    { name: "Ativos", value: "1" },
    { name: "Inativos", value: "0" },
  ];
  const optionsEstoque = [
    { name: "Todos estoques", value: "todos" },
    { name: "Com estoque", value: "1" },
    { name: "Sem estoque", value: "0" },
    { name: "Sem controle de estoque", value: "-1" },
  ];

  const optionsVisualizacao = [
    { name: "Todos produtos", value: "todos" },
    { name: "Produtos adicionados", value: "adicionados" },
    { name: "Produtos não adicionados", value: "nao-adicionados" },
    { name: "Produtos com foto", value: "com-foto" },
    { name: "Produtos sem foto", value: "sem-foto" },
  ];

  const optionsOrdenacao = [
    { name: "Nome A-Z", value: "referencia-asc" },
    { name: "Nome Z-A", value: "referencia-desc" },
    { name: "Maior estoque", value: "estoque-desc" },
    { name: "Menor estoque", value: "estoque-asc" },
    { name: "Mais novos", value: "data-asc" },
    { name: "Mais antigos", value: "data-desc" },
    { name: "Maior preço", value: "preco-desc" },
    { name: "Menor preço", value: "preco-asc" },
  ];

  const getCategorias = async () => {
    const categorias = await apiProdutos.getCategorias();
    setCategorias([
      { name: "Todas as categorias", value: "0" },
      ...categorias.map((categoria: Categoria) => {
        return {
          name: categoria.descricao,
          value: categoria.id,
        };
      }),
    ]);
  };

  const init = async () => {
    setLoading(true);
    
    const produtosRes = await apiProdutos.getProdutos(
      tipoFiltro === "listagem-catalogo" ? true : false,
      tipoFiltro === "listagem-catalogo" ? catalogo?.id_tabela : undefined,
    );

    setProdutos(produtosRes);
  };

  const handleFilter = (filtros: FiltrosProdutos) => {
    setLoading(true);

    let novosProdutosFiltrados = [...produtos];

    if (tipoFiltro === "listagem-padrao") {
      novosProdutosFiltrados = filterByAtivos(novosProdutosFiltrados, filtros);
      novosProdutosFiltrados = filterByCategoria(
        novosProdutosFiltrados,
        filtros
      );
      novosProdutosFiltrados = filterByEstoque(novosProdutosFiltrados, filtros);
      novosProdutosFiltrados = filterByPesquisa(
        novosProdutosFiltrados,
        filtros
      );
    } else if (tipoFiltro === "listagem-catalogo") {
      novosProdutosFiltrados = filterByPrecos(
        novosProdutosFiltrados,
        filtros
      );
      novosProdutosFiltrados = filterByCategoria(
        novosProdutosFiltrados,
        filtros
      );
      novosProdutosFiltrados = filterByVisualizacao(
        novosProdutosFiltrados,
        filtros,
        catalogo!
      );
      novosProdutosFiltrados = filterByEstoque(novosProdutosFiltrados, filtros);
      
      novosProdutosFiltrados = filterByPesquisa(
        novosProdutosFiltrados,
        filtros
      );
      novosProdutosFiltrados = sortProdutos(novosProdutosFiltrados, filtros);
    }
    
    setProdutosFiltrados(novosProdutosFiltrados);
  };

  const filterByPrecos = (produtos: Produto[], filtros: FiltrosProdutos) => {
    const minValue = filtros.filtroPrecoMin;
    const maxValue = filtros.filtroPrecoMax;

    return produtos.filter((produto) => {
      const preco = produto.preco ? Number(produto.preco.replace(",", ".")) : 0;
      return preco >= minValue && preco <= maxValue;
    });
  }

  const filterByAtivos = (produtos: Produto[], filtros: FiltrosProdutos) => {
    return produtos.filter((produto) => boolean(produto.ativo) === boolean(filtros.filtroAtivos));
  };

  const filterByCategoria = (produtos: Produto[], filtros: FiltrosProdutos) => {
    return produtos.filter(
      (produto) =>
        filtros.filtroCategoria === "0" ||
        produto.id_pasta.toString() === filtros.filtroCategoria.toString()
    );
  };

  const filterByEstoque = (produtos, filtros) => {
    return produtos.filter((produto) => {
      if (filtros.filtroEstoque === "todos") {
        return true;
      } else if (filtros.filtroEstoque === "1") {
        return Number.parseInt(produto.estoque) > 0;
      } else if (filtros.filtroEstoque === "0") {
        return Number.parseInt(produto.estoque) === 0;
      } else {
        return (
          produto.estoque === "" ||
          produto.estoque === null ||
          Number.parseInt(produto.estoque) < 0
        );
      }
    });
  };

  const filterByVisualizacao = (
    produtos: Produto[],
    filtros: FiltrosProdutos,
    catalogo: CatalogoUnico | null
  ) => {
    if (filtros.filtroVisualizacao === "todos") {
      return produtos;
    }

    return produtos.filter((produto) => {
      const isProdutoInCatalogo = catalogo!.produtos.includes(produto.id);
      const hasFoto = produto.imagens[0] || produto.imagem_externa != null;

      switch (filtros.filtroVisualizacao) {
        case "adicionados":
          return isProdutoInCatalogo;
        case "nao-adicionados":
          return !isProdutoInCatalogo;
        case "com-foto":
          return hasFoto;
        case "sem-foto":
          return !hasFoto;
        default:
          return true;
      }
    });
  };

  const filterByPesquisa = (produtos, filtros) => {
    if (filtros.filtroPesquisa !== "") {
      const palavrasPesquisa = filtros.filtroPesquisa
        .split(';')
        .map(palavra => cleanToFilter(palavra.trim()))
        .filter(palavra => palavra !== '');
  
      return produtos.filter(produto => {
        const camposParaPesquisar = ['referencia', 'descricao', 'nome'];

        return palavrasPesquisa.some(palavraPesquisa =>
          camposParaPesquisar.some(campo => 
            cleanToFilter(produto[campo] ?? '').includes(palavraPesquisa)
          )
        );
      });
    }
    return produtos;
  };

  const sortProdutos = (produtos: Produto[], filtros: FiltrosProdutos) => {
    switch (filtros.filtroOrdenacao) {
      case "referencia-asc":
        return produtos.sort((a, b) =>
          a.referencia.localeCompare(b.referencia)
        );
      case "referencia-desc":
        return produtos.sort((a, b) =>
          b.referencia.localeCompare(a.referencia)
        );
      case "estoque-desc":
        return produtos.sort(
          (a, b) => Number.parseInt(b.estoque) - Number.parseInt(a.estoque)
        );
      case "estoque-asc":
        return produtos.sort(
          (a, b) => Number.parseInt(a.estoque) - Number.parseInt(b.estoque)
        );
      case "data-asc":
        return produtos.sort((a, b) => {
          if (a.data_criacao_real === null && b.data_criacao_real === null)
            return 0;
          if (a.data_criacao_real === null) return 1; // null dates come last
          if (b.data_criacao_real === null) return -1; // non-null dates come first
          return moment(a.data_criacao_real).isBefore(b.data_criacao_real)
            ? 1
            : -1;
        });
      case "data-desc":
        return produtos.sort((a, b) => {
          if (a.data_criacao_real === null && b.data_criacao_real === null)
            return 0;
          if (a.data_criacao_real === null) return 1; // null dates come last
          if (b.data_criacao_real === null) return -1; // non-null dates come first
          return moment(b.data_criacao_real).isBefore(a.data_criacao_real)
            ? 1
            : -1; // Inverted comparison
        });
      case "preco-asc":
        return produtos.sort((a, b) => {
          const precoA = a.preco ? Number(a.preco.replace(",", ".")) : 0;
          const precoB = b.preco ? Number(b.preco.replace(",", ".")) : 0;
          return precoA - precoB;
        });
      case "preco-desc":
        return produtos.sort((a, b) => {
          const precoA = a.preco ? Number(a.preco.replace(",", ".")) : 0;
          const precoB = b.preco ? Number(b.preco.replace(",", ".")) : 0;
          return precoB - precoA;
        });

      default:
        return produtos;
    }
  };

  useEffect(() => {
    if (produtos.length) handleFilter(filtros);
    setLoading(false);
  }, [produtos, filtros]);

  useEffect(() => {
    setLoading(false);
  }, [produtosFiltrados]);

  useEffect(() => {
    getCategorias();
    if (tipoFiltro !== "listagem-catalogo") {
      init();
    }
  }, []);

  useEffect(() => {
    saveToLocalStorage(filtros, filtroPesquisaAux);
  }, [filtros, filtroPesquisaAux]);

  useEffect(() => {
    if (catalogoContext?.catalogo) {
      setCatalogo(catalogoContext.catalogo);
    }
  }, [catalogoContext?.catalogo]);

  return useMemo(() => {
    return (
      <ProdutosContext.Provider
        value={{
          loading,
          setLoading,
          newProductModal,
          setNewProductModal,
          filtros,
          setFiltros,
          produtos,
          produtosFiltrados,
          filtroPesquisaAux,
          setFiltroPesquisaAux,
          optionsVisualizacao,
          optionsOrdenacao,
          categorias,
          optionsAtivos,
          optionsEstoque,
          init,
        }}
      >
        {children}
      </ProdutosContext.Provider>
    );
  }, [
    loading,
    newProductModal,
    filtros,
    produtos,
    produtosFiltrados,
    filtroPesquisaAux,
    categorias,
    catalogo,
  ]);
};
