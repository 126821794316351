import styled from "styled-components";
import theme from "../../Themes/light";

const ContainerPrintablePedido = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    @page {
      margin: 35px 40px;
    }
    page-break-after: always;
    width: 100%;
    .container {
      position: relative;
      width: 100%;
      color: ${theme.colors.brandSecondary} !important;
      padding: 0 20px;
      .flex {
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        color: inherit !important;
        .titulo {
          font-size: 18px;
          font-weight: bold;
          color: inherit !important;
        }
        span {
          font-size: 11px;
          font-weight: bold;
          color: inherit !important;
        }
        div {
          font-size: 11px;
          color: inherit !important;
        }
      }
      .resumo {
        padding: 20px 0 20px 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        text-justify: right;
        gap: 20px;
        div {
          font-size: 11px;
          color: ${theme.colors.brandSecondary} !important;
          font-weight: bold;
        }
      }
    }
    .container-linha {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15%;
    }
    .linha {
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.placeholder} !important;
      border-radius: 50px;
    }

  }
`;

export { ContainerPrintablePedido };

