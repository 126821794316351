import moment from "moment";
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ReactToPrint from "react-to-print";
import { Button } from "../../../components/Button";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import Paginacao from "../../../components/Paginacao";
import { Select } from "../../../components/Select";
import { SkeletonTableCestas } from "../../../components/Skeletons/SkeletonTableCestas/SkeletonTableCestas";
import Table from "../../../components/Table/Table";
import { RelatoriosContext } from "../../../contexts/Relatorios/RelatoriosContext";
import { cepMask, cpfMask, phoneMask } from "../../../services/Masks";
import { Relatorio as RelatorioType } from "../../../types/Relatorios";
import PrintableRelatorio from "../PrintableRelatorio/PrintableRelatorio";
import "./styles.ts";
import RelatorioWrapper from "./styles.ts";

interface RelatorioProps {
  relatorio: RelatorioType;
}

const Relatorio: React.FC<RelatorioProps> = ({ relatorio }) => {
  const { gerarRelatorio, exportarRelatorio } = useContext(RelatoriosContext);

  const tableRef = useRef<HTMLTableElement>(null);

  //paginacao
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState<any>({});
  const [formValid, setFormValid] = useState(false);

  const [relatorioData, setRelatorioData] = useState<Array<{}>>();
  const [relatorioColumns, setRelatorioColumns] = useState<
    Array<{ key: string; label: string }>
  >([]);

  const handleFiltrar = async () => {
    setLoading(true);
    const res = await gerarRelatorio(relatorio.id!, filtros);
    if (res.length === 0) return setRelatorioData([]);

    let columns: any = [];
    Object.keys(res[0]).forEach((key) => {
      columns.push({
        key: key,
        label: formatarLabel(key),
        mask: applyMask(key),
        allowHTML: false,
      });
    });
    
    setRelatorioColumns(columns);
    setRelatorioData(res);
    setTotalPages(Math.ceil(res.length / itensPorPagina));
    setCurrentPage(1);
  };

  const exportarRelatorioDowload = async () => {
    const link = await exportarRelatorio(relatorio.id!, filtros);
    window.open(link, "_blank");
  };

  const formatarLabel = (label: string) => {
    let newLabel = label.replace(/_/g, " ");
    newLabel = newLabel.charAt(0).toUpperCase() + newLabel.slice(1);
    return newLabel;
  };

  const applyMask = (key: string) => {
    switch (key.toLowerCase().replace(/ /g, "_").replace(/"/g, "")) {
      case "data":
        return (value: string) => {
          if (value.includes(" ") || value.includes(":")) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
          } else {
            return moment(value).format("DD/MM/YYYY");
          }
        };
      case "valor_total":
      case "valor":
        return (value: string) => `R$ ${value}`;
      case "cpf":
        return cpfMask;
      case "cep":
        return cepMask;
      case "telefone":
        return phoneMask;
      default:
        return (value: string) => value;
    }
  };

  const memoizedTableColumn = useMemo(
    () => relatorioColumns,
    [relatorioColumns]
  );
  const memoizedTableData = useMemo(() => relatorioData, [relatorioData]);

  useEffect(() => {
    if (relatorioData) {
      setLoading(false);
    }
  }, [relatorioData]);

  useEffect(() => {
    if (relatorio.variables) {
      let newFiltros: any = {};
      relatorio.variables?.forEach((variable) => {
        if (variable.type === "date") {
          if(variable.label === "De"){
            newFiltros[variable.variable!] = moment().subtract(1, 'months').format("YYYY-MM-DD");

          }else{
            newFiltros[variable.variable!] = moment().format("YYYY-MM-DD");
          }
           //default value pra campo date
          } else {
            newFiltros[variable.variable!] = "";
          }
          if (variable.label === "Catálogo") { 
           newFiltros[variable.variable!] = variable.options ? variable.options[0].value : '';
          }
      });
      setFiltros(newFiltros);
    } else {
      handleFiltrar();
    }
  }, [relatorio]);

  return (
    <RelatorioWrapper>
      {relatorio.variables && relatorio.variables.length && (
        <Form
          className="relatorio-header"
          id="form-variables-relatorios"
          setFormValid={setFormValid}
          onSubmit={handleFiltrar}
        >
          <div className="variables-wrapper">
            {relatorio.variables.map((variable) => (
              <>
                {(() => {
                  switch (variable.tag) {
                    case "input":
                      return (
                        <div className="input-wrapper">
                          <Input
                            type={variable.type!}
                            value={filtros[variable.variable!]}
                            label={variable.label!}
                            sizeLabel="medium"
                            placeholder={variable.placeholder!}
                            onChange={(newValue) => {
                              setFiltros({
                                ...filtros,
                                [variable.variable!]: newValue,
                              });
                            }}
                            required={true}
                          />
                        </div>
                      );
                    case "select":
                      return (
                        <div className="select-wrapper">
                          <Select
                            options={variable.options!}
                            value={filtros[variable.variable!]}
                            label={variable.label!}
                            sizeLabel="medium"
                            placeholder={variable.placeholder!}
                            onChange={(newValue) => {
                              setFiltros({
                                ...filtros,
                                [variable.variable!]: newValue,
                              });
                            }}
                            required={true}
                          />
                        </div>
                      );
                    default:
                      return <div></div>;
                  }
                })()}
              </>
            ))}
          </div>
          <Button
            className={!formValid ? "disabled" : ""}
            type="submit"
            title="Filtrar"
          />
        </Form>
      )}
      {relatorioData && !loading ? (
        <>
          <Table
            className="screen-table"
            columns={relatorioColumns}
            data={relatorioData!}
            sortable={true}
            selectable={false}
            button={false}
            page={currentPage}
            mostrarTotais={true}
            itensPorPagina={itensPorPagina}
          />
          <PrintableRelatorio
            ref={tableRef}
            relatorio={relatorio}
            tableColumns={memoizedTableColumn}
            tableData={memoizedTableData || []}
          />
        </>
      ) : (
        loading && <SkeletonTableCestas />
      )}
      {relatorioData && relatorioData.length > 0 && !loading && (
        <FooterPaginas>
          <div className="flex-footer-relatorios">
            <span>
              <Paginacao
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              ></Paginacao>
            </span>
            <span className="botoes">
              <Button
                className="botao exportar"
                title=""
                onClick={exportarRelatorioDowload}
              ></Button>
              <ReactToPrint
                removeAfterPrint={true}
                trigger={() => <Button className="botao imprimir" title="" />}
                content={() => tableRef.current}
              />
            </span>
            {relatorioData &&
              relatorioData.length > 0 &&
              relatorioColumns.find(
                // || campo.key === "valor" | Caso tiver um novo campo chamado "valor"
                (campo) => campo.key === "valor_total" 
              ) && (
                <span className="valor">
                  <div className="valor-flex">
                    <span className="texto">Valor total:</span>
                    <span>
                      {(relatorioData &&
                        relatorioData
                          .reduce((acumulador: number, data: any) => {
                            return (
                              acumulador +
                                Number.parseFloat(data.valor_total) || 0
                            );
                          }, 0)
                          .toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })) ||
                        ""}
                    </span>
                  </div>
                </span>
              )}
          </div>
        </FooterPaginas>
      )}
    </RelatorioWrapper>
  );
};

export default Relatorio;
