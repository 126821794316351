import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import { ReactComponent as SlidersSVG } from "../../assets/newImages/icons/sliders-v.svg";
import AsideMenu from "../../components/AsideMenu/AsideMenu";
import { Button } from "../../components/Button";
import { Filtros } from "../../components/Filtros/Filtros";
import { FiltrosData } from "../../components/FiltrosData/FiltrosData";
import { FooterPaginas } from "../../components/FooterPaginas/FooterPaginas";
import { Input } from "../../components/Input";
import LoadingComFundo from "../../components/Loading/LoadingComFundo/LoadingComFundo.tsx";
import Paginacao from "../../components/Paginacao";
import { Select } from "../../components/Select";
import { SelectPedidos } from "../../components/Select/SelectPedidos";
import { SkeletonTable } from "../../components/Skeletons/SkeletonTable/SkeletonTable";
import Table from "../../components/Table/Table";
import { Toggle } from "../../components/Toggle/Toggle";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { CatalogosApi } from "../../hooks/catalogos";
import { PedidosApi } from "../../hooks/pedidosApi";
import { PerfilApi } from "../../hooks/perfilApi";
import { cleanToFilter, copy } from "../../services/Functions";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  phoneMask,
  situacaoErpMask,
} from "../../services/Masks";
import PedidoListagem from "../../types/PedidoListagem";
import PedidosImpressao from "./PedidosImpressao/PedidosImpressao";
import { ContainerPedidos } from "./styles.ts";

type camposListagem = 
'id_pedido' |
'nome_razao_social' |
'data_criacao' |
'moeda' |
'valor_total' |
'cpf_cnpj' |
'inscricao_estadual' |
'email' |
'telefone' |
'data_nascimento' |
'catalogo' |
'cupom' |
'tabela_preco' |
'usuario' |
'observacao' |
'observacao_interna' |
'estado' |
'cep' |
'cidade' |
'bairro' |
'erp_situacao' |
'endereco_completo';

interface PedidosProps {}

const cores = {
  // cores conforme o id da situação no banco
  1: "#FFBE9D",
  7: "#E7BE00",
  8: "#4DE889",
  4: "#00A2BB",
  5: "#0093FB",
  6: "#FF6E28",
  2: "#0D5229",
  3: "#9BA9FB",
  9: "#FF0000",
};

const camposPersonalizados:
  Array<{
    nome: any;
    campo: camposListagem;
    mask?: (value: any) => any;
  }>
= [
  {
    nome: "Nº do pedido",
    campo: "id_pedido",
    mask: (value: string) => {
      return `#${value}`;
    },
  },
  {
    nome: "Cliente",
    campo: "nome_razao_social",
  },
  {
    nome: "Data",
    campo: "data_criacao",
    mask: (value) => dateMask(value),
  },
  {
    nome: "Cifrão",
    campo: "moeda",
  },
  {
    nome: "Valor",
    campo: "valor_total",
    mask: (value) => {
      return Number(value).toFixed(2).replace(".", ",");
    },
  },
  {
    nome: "CPF/CNPJ",
    campo: "cpf_cnpj",
    mask: (value: string) => {
      return value.length <= 11 ? cpfMask(value) : cnpjMask(value);
    },
  },
  {
    nome: "Inscrição Estadual",
    campo: "inscricao_estadual",
  },
  { nome: "E-mail", campo: "email" },
  {
    nome: "Telefone",
    campo: "telefone",
    mask: phoneMask,
  },
  {
    nome: "Data de nascimento",
    campo: "data_nascimento",
    mask: (value) => dateMask(value),
  },
  { nome: "Catálogo", campo: "catalogo" },
  {
    nome: "Cupom",
    campo: "cupom",
  },
  {
    nome: "Tabela de preço",
    campo: "tabela_preco",
  },
  { nome: "Vendedor", campo: "usuario" },
  {
    nome: "Observação do pedido",
    campo: "observacao",
  },
  {
    nome: "Observação interna",
    campo: "observacao_interna",
  },
  { nome: "UF", campo: "estado" },
  { nome: "CEP", campo: "cep", mask: cepMask },
  { nome: "Cidade", campo: "cidade" },
  { nome: "Bairro", campo: "bairro" },
  {
    nome: function () {
      return <div style={{ textAlign: "center" }}>Status ERP</div>;
    },
    campo: "erp_situacao",
    mask: situacaoErpMask,
  },
  {
    nome: "Endereço, nº e complemento",
    campo: "endereco_completo",
  },
];

const Pedidos: React.FC<PedidosProps> = ({}) => {
  const { theme } = useTheme();

  const impressaoRef = useRef<any>();

  const [asideMenu, setAsideMenu] = useState(false);

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [loading, setLoading] = useState(false);

  const [animationKey, setAnimationKey] = useState(Math.random());
  const navigate = useNavigate();

  const { erp_empresa } = useContext(EmpresaContext);

  const [camposAtivos, setCamposAtivos] = 
    useState<camposListagem[]>(
      localStorage.getItem("camposAtivosPedidos") ?
        JSON.parse(localStorage.getItem("camposAtivosPedidos")!) as camposListagem[] :
        camposPersonalizados.map(campo => campo.campo));
  const [camposAtivosAux, setCamposAtivosAux] = useState<camposListagem[]>(copy(camposAtivos));

  //api
  const perfilApi = PerfilApi();
  const catalogosApi = CatalogosApi();
  const pedidosApi = PedidosApi();

  //paginacao
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itensPorPagina, setItensPorPagina] = useState(50);

  const [pedidos, setPedidos] = useState<Record<number, PedidoListagem[]>>({});
  const [pedidosFiltrados, setPedidosFiltrados] = useState<
    Record<number, PedidoListagem[]> | undefined
  >(undefined);
  const [pedidosSelecionados, setPedidosSelecionados] = useState<
    PedidoListagem[]
  >([]);
  const [situacoes, setSituacoes] = useState<
    Array<{ value: number; name: string; quantidade?: number }>
  >([]);
  const [situacoesFiltradas, setSituacoesFiltradas] = useState<
    Array<{ value: number; name: string; quantidade?: number }> | undefined
  >(undefined);

  const [optionsUsuarios, setOptionsUsuarios] = useState<
    { name: string; value: string }[]
  >([]);

  const [optionsCatalogos, setOptionsCatalogos] = useState<
    { name: string; value: string }[]
  >([]);

  //filtros
  const [filtroPesquisa, setFiltroPesquisa] = useState("");
  const [situacaoAtiva, setSituacaoAtiva] = useState("1");
  const [filtroUsuario, setFiltroUsuario] = useState("");
  const [filtroCatalogo, setFiltroCatalogo] = useState("");
  const [dataInicio, setDataInicio] = useState(
    `${moment().subtract(30, "days").format("YYYY-MM-DD")} 00:00:00`
  );
  const [dataFim, setDataFim] = useState(
    `${moment().format("YYYY-MM-DD")} 23:59:59`
  );

  //auxiliares
  const [filtroUsuarioAux, setFiltroUsuarioAux] = useState("");
  const [filtroCatalogoAux, setFiltroCatalogoAux] = useState("");
  const [filtroPesquisaAux, setFiltroPesquisaAux] = useState("");
  const [pedidosImpressao, setPedidosImpressao] = useState<[]>([]);

  //table
  const [tableColumns, setTableColumns] = useState<
    Array<{ key: string; label: string }>
  >([]);

  const init = async (
    dataInicio: string,
    dataFim: string,
    filtroUsuario: string,
    filtroCatalogo: string,
    filtroPesquisa: string,
    camposAtivos: camposListagem[]
  ) => {
    setLoadingSkeleton(true);

    try {
      const [usuarios, catalogos, pedidos, situacoes] =
        await Promise.all([
          perfilApi.usuarios(),
          catalogosApi.getCatalogosFiltroPedidos(),
          pedidosApi.getPedidos(
            camposAtivos,
            { inicio: dataInicio, fim: dataFim },
            {
              usuario: filtroUsuario,
              catalogo: filtroCatalogo,
              pesquisa: filtroPesquisa,
              erp_empresa: erp_empresa,
            }
          ),
          pedidosApi.getSituacoes(),
        ]);

      setPedidos(pedidos);

      setSituacoes(
        situacoes.map((situacao: any) => ({
          name: situacao.descricao,
          value: situacao.id.toString(),
          quantidade: pedidos[situacao.id]
            ? pedidos[situacao.id].length || ""
            : "",
        }))
      );

      setOptionsUsuarios([
        { name: "Todos os usuários", value: "" },
        ...usuarios.map((usuario: any) => ({
          name: usuario.nome,
          value: usuario.nome,
        })),
      ]);

      setOptionsCatalogos([
        { name: "Todos os catálogos", value: "" },
        ...catalogos.map((catalogo: any) => ({
          name: catalogo.descricao,
          value: catalogo.id,
        })),
      ]);

      setTableColumns(
        camposPersonalizados
          .filter((campo) => camposAtivos.includes(campo.campo))
          .map((campo) => ({
            key: campo.campo,
            label: campo.nome,
            mask: campo.mask,
          }))
      );

      if (pedidos[situacaoAtiva]){
        setTotalPages(
          Math.ceil(pedidos[situacaoAtiva].length / itensPorPagina)
        );
      }

      setCurrentPage(1);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocorreu um erro ao carregar os pedidos.",
        showConfirmButton: true,
      });
      navigate("/painel");
    }

    setLoadingSkeleton(false);
  };

  const filtrarData = (data: {
    inicio: string;
    fim: string;
    placeholder: string;
  }) => {
    setDataInicio(data.inicio);
    setDataFim(data.fim);

    init(data.inicio, data.fim, filtroUsuario, filtroCatalogo, filtroPesquisa, camposAtivos);
  };

  const onAplicarFiltro = (filtroUsuario: string, filtroCatalogo: string) => {
    setFiltroUsuario(filtroUsuario);
    setFiltroCatalogo(filtroCatalogo);

    init(dataInicio, dataFim, filtroUsuario, filtroCatalogo, filtroPesquisa, camposAtivos);
  };

  const handleAplicarCampos = async () => {
    localStorage.setItem("camposAtivosPedidos", JSON.stringify(camposAtivos));
    init(dataInicio, dataFim, filtroUsuario, filtroCatalogo, filtroPesquisa, camposAtivos);
  };

  const handleChangeSituacao = (situacao: string) => {
    if (situacao === situacaoAtiva) return;
    setSituacaoAtiva(situacao);
    setCurrentPage(1);
    setPedidosSelecionados([]);
    pedidosFiltrados !== undefined && pedidosFiltrados[situacao]
      ? setTotalPages(
          Math.ceil(pedidosFiltrados[situacao].length / itensPorPagina)
        )
      : pedidos[situacao]
      ? setTotalPages(Math.ceil(pedidos[situacao].length / itensPorPagina))
      : setTotalPages(0);
    localStorage.setItem("situacao", situacao);
    setSituacoes(situacoes);
  };

  const handlePaginacao = (page: number) => {
    setCurrentPage(page);
  };

  const showSwalConfirmation = async (options: any) => {
    const result = await Swal.fire(options);
    return result.isConfirmed;
  };

  const updatePedidosSituacao = async (situacao: number) => {
    const pedidosParaAlterar = pedidosSelecionados.map(
      (pedido) => pedido.id_pedido
    );
    if (
      await pedidosApi
        .alterarSituacao(pedidosParaAlterar, situacao)
        .catch((err) => {
          init(
            dataInicio,
            dataFim,
            filtroUsuario,
            filtroCatalogo,
            filtroPesquisa,
            camposAtivos
          );
          return false;
        })
    ) {
      init(dataInicio, dataFim, filtroUsuario, filtroCatalogo, filtroPesquisa, camposAtivos);
      setPedidosSelecionados([]);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Pedido(s) alterado(s).",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const handleMoverPedidos = async (situacao: string) => {
    if (situacao === situacaoAtiva) return;

    const isCancelled = situacao === "3";
    const confirmationOptions = isCancelled
      ? {
          title: "Tem certeza que deseja cancelar o(s) pedido(s)?",
          text: "Esta ação não poderá ser desfeita e irá repor os estoques dos produtos caso você utilize.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Cancelar pedido(s)",
          cancelButtonText: "Cancelar",
        }
      : {
          title: "Tem certeza que deseja alterar a situação do(s) pedido(s)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Alterar pedido(s)",
          cancelButtonText: "Cancelar",
        };

    const confirmed = await showSwalConfirmation(confirmationOptions);
    if (confirmed) {
      setLoadingSkeleton(true);

      await updatePedidosSituacao(Number.parseInt(situacao));
    }
  };

  const handleImprimirPedidos = async () => {
    setLoading(true);

    const pedidosImpressao = await pedidosApi.getPedidosImpressao(
      pedidosSelecionados.map((pedido: any) => pedido.id_pedido)
    );
    setPedidosImpressao(pedidosImpressao);
  };

  const checkImagesLoaded = (container: any) => {
    //funcao async que verifica se todas as imagens foram carregadas
    return new Promise((resolve, reject) => {
      let images = container.querySelectorAll("img");
      let loadedImages = 0;

      if (images.length === 0) {
        resolve(true);
      }

      const onImageLoaded = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          resolve(true);
        }
      };

      images.forEach(image => {
        if (image.complete && image.naturalHeight !== 0) {
          onImageLoaded();
        } else {
          image.addEventListener('load', onImageLoaded, { once: true });
        }
      });
    });
  }

  const handlePrint = useReactToPrint({
    content: () => impressaoRef.current,
    onBeforeGetContent: async () => {
      await checkImagesLoaded(impressaoRef.current);
      //mesmo com a funcao acima ainda tem imagens que nao carregavam, entao coloquei um timeout 
      //e funcionou, timeout de 0 segundos supremacy
      await new Promise(resolve => setTimeout(resolve, 0));
    },
    onBeforePrint: () => {
    },
    onPrintError: () => {
      setLoading(false);
      setPedidosImpressao([]);
    },
    onAfterPrint: () => {
      setLoading(false);
      setPedidosImpressao([]);
    },
  });

  useEffect(() => {
    if (pedidosImpressao && pedidosImpressao.length) {
      handlePrint();
    }
  }, [pedidosImpressao]); // Dependencies array
  

  const handleDetalhesPedido = (pedido: PedidoListagem) => {
    navigate(`/painel/pedidos/pedido/${pedido.id_pedido}`);
  };

  // animar ao selecionar/deselecionar pedidos
  const prevPedidosSelecionadosLength = useRef(pedidosSelecionados.length);
  useEffect(() => {
    if (
      (prevPedidosSelecionadosLength.current > 0 &&
        pedidosSelecionados.length === 0) ||
      (prevPedidosSelecionadosLength.current === 0 &&
        pedidosSelecionados.length > 0)
    ) {
      setAnimationKey(Math.random());
    }

    prevPedidosSelecionadosLength.current = pedidosSelecionados.length;
  }, [pedidosSelecionados]);

  useEffect(() => {
    setAnimationKey(Math.random());
  }, [situacaoAtiva]);

  //filtro de pesquisa
  useEffect(() => {
    if (filtroPesquisa === "") {
      setPedidosFiltrados(undefined);
      setSituacoesFiltradas(undefined);
      if (pedidos[situacaoAtiva]) {
        setTotalPages(
          Math.ceil(pedidos[situacaoAtiva].length / itensPorPagina)
        );
        setCurrentPage(1);
      }
      setLoadingSkeleton(false);
    } else {
      const filtro = cleanToFilter(filtroPesquisa);
      // filtra todos os pedidos em todas as situacoes
      const novosPedidosFiltrados = Object.entries(pedidos).reduce(
        (acc, [key, pedidoList]) => {
          acc[key] = pedidoList.filter((pedido: PedidoListagem) => {
            return Object.values(pedido).some((value) =>
              cleanToFilter(String(value)).includes(filtro)
            );
          });
          return acc;
        },
        {}
      );

      if (Object.keys(novosPedidosFiltrados).length !== 0) {
        setSituacoesFiltradas(
          situacoes.map((situacao: any) => ({
            name: situacao.name,
            value: situacao.value,
            quantidade: novosPedidosFiltrados[Number.parseInt(situacao.value)]
              ? novosPedidosFiltrados[Number.parseInt(situacao.value)].length
              : 0,
          }))
        );
        setPedidosFiltrados(novosPedidosFiltrados);
        setTotalPages(
          Math.ceil(
            novosPedidosFiltrados[situacaoAtiva]
              ? Math.ceil(
                  novosPedidosFiltrados[situacaoAtiva].length / itensPorPagina
                )
              : 0
          )
        );
      } else {
        setSituacoesFiltradas(
          situacoes.map((situacao: any) => ({
            name: situacao.name,
            value: situacao.value,
            quantidade: 0,
          }))
        );
        setPedidosFiltrados([]);
        setTotalPages(0);
      }

      setCurrentPage(1);
      setLoadingSkeleton(false);
    }
  }, [filtroPesquisa, pedidos]);

  useEffect(() => {
    init(dataInicio, dataFim, filtroUsuario, filtroCatalogo, filtroPesquisa, camposAtivos);
  }, []);

  useEffect(() => {
    const situacaoSalva = localStorage.getItem("situacao");
    if (situacaoSalva) {
      setSituacaoAtiva(situacaoSalva);
    }
  }, []);

  if (loading) {
    return (
      <>
        <PedidosImpressao pedidos={pedidosImpressao} ref={impressaoRef} />
        <LoadingComFundo mensagem="Imprimindo pedidos..." position="fixed" />
      </>
    );
  }

  return (
    <>
      <ContainerPedidos theme={theme}>
        <div className="filtros-container">
          <div className="filtros">
            <div className="filtroPesquisa">
              <Input
                type={"text"}
                icone="icons/search.svg"
                placeholder={"Pesquisar por código, cliente ou catálogo"}
                value={filtroPesquisaAux}
                onChange={(newValue) => {
                  newValue === filtroPesquisa
                    ? setLoadingSkeleton(false)
                    : setLoadingSkeleton(true);
                  setFiltroPesquisaAux(newValue);
                }}
                onDebounce={(newValue) => setFiltroPesquisa(newValue)}
                debounceTime={500}
              ></Input>
            </div>
            <div className="filtro">
              <Filtros
                onAplicar={() => {
                  onAplicarFiltro(filtroUsuarioAux, filtroCatalogoAux);
                }}
                onCancelar={() => {
                  setFiltroUsuarioAux(filtroUsuario);
                  setFiltroCatalogoAux(filtroCatalogo);
                }}
              >
                <div className="containerSelects">
                  <Select
                    options={optionsUsuarios}
                    value={filtroUsuarioAux}
                    label="Usuário"
                    placeholder="Todos os usuários"
                    onChange={(newValue) => setFiltroUsuarioAux(newValue)}
                  ></Select>
                  <Select
                    options={optionsCatalogos}
                    value={filtroCatalogoAux}
                    label="Catálogo"
                    placeholder="Todos os catálogos"
                    onChange={(newValue) => setFiltroCatalogoAux(newValue)}
                  ></Select>
                </div>
              </Filtros>
            </div>
            <div className="filtro-data">
              <FiltrosData
                onAplicar={(newValue) => filtrarData(newValue)}
                value={{
                  inicio: dataInicio,
                  fim: dataFim,
                  placeholder: "Últimos 30 dias",
                }}
              ></FiltrosData>
            </div>
          </div>
          <div className="inf-visiveis-container">
            <div className="selectContainer hideDesktop">
              <SelectPedidos
                options={situacoesFiltradas || situacoes}
                placeholder="Situação"
                value={situacaoAtiva}
                cores={cores}
                onChange={(newValue) => {
                  handleChangeSituacao(newValue);
                }}
              ></SelectPedidos>
            </div>
            <label
              className="inf-visiveis"
              onClick={() => setAsideMenu(!asideMenu)}
            >
              <label className="texto">Configurar informações visíveis</label>
              <SlidersSVG />
            </label>
          </div>
        </div>
        <AsideMenu
          isOpen={asideMenu}
          setIsOpen={setAsideMenu}
          titulo="Meus pedidos /"
          subtitulo="Informações visíveis"
          disabled={camposAtivos.length === 0}
          onAplicar={handleAplicarCampos}
          onCancelar={() => {
            setCamposAtivos(camposAtivosAux);
          }}
          onOpen={() => {
            setCamposAtivosAux(copy(camposAtivos));
          }}
        >
          <div className="container-toggles">
            <div className="texto">
              Selecione abaixo quais colunas <br /> deseja que estejam visíveis
            </div>
            <div className="campos scrollBonito">
              {camposPersonalizados.map(
                (
                  campo: {
                    nome: string | (() => string);
                    campo: camposListagem;
                  },
                  index: number
                ) => {
                  return (
                    <div key={index}>
                      <label className="campo">
                        <Toggle
                          value={camposAtivos.includes(campo.campo)}
                          onChange={(newValue) => {
                            if (newValue) {
                              setCamposAtivos([...camposAtivos, campo.campo]);
                            } else {
                              setCamposAtivos(
                                camposAtivos.filter(
                                  (campoAtivo) => campoAtivo !== campo.campo
                                )
                              );
                            }
                          }}
                        />
                        {typeof campo.nome === "function"
                          ? campo.nome()
                          : campo.nome}
                      </label>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </AsideMenu>
        {!loadingSkeleton && (
          <div className="containerSituacoes hideMobile">
            <div className="situacoes">
              {situacoesFiltradas !== undefined
                ? situacoesFiltradas.map((situacao: any, index: number) => {
                    return (
                      <>
                        <div key={index} className="containerSituacao">
                          <div
                            className={`situacao ${
                              situacao.value === situacaoAtiva && "ativa"
                            }`}
                            onClick={() => handleChangeSituacao(situacao.value)}
                          >
                            <div className="nome">{situacao.name}</div>
                            <div className="quantidade">
                              {situacao ? situacao.quantidade : " "}
                            </div>
                          </div>
                          {situacaoAtiva === situacao.value && (
                            <div
                              className="linha"
                              style={{ background: cores[situacaoAtiva] }}
                            ></div>
                          )}
                        </div>
                      </>
                    );
                  })
                : situacoes.map((situacao: any, index: number) => {
                    return (
                      <>
                        <div key={index} className="containerSituacao">
                          <div
                            className={`situacao ${
                              situacao.value === situacaoAtiva && "ativa"
                            }`}
                            onClick={() => handleChangeSituacao(situacao.value)}
                          >
                            <div className="nome">{situacao.name}</div>
                            <div className="quantidade">
                              {situacao.quantidade ? situacao.quantidade : " "}
                            </div>
                          </div>
                          {situacaoAtiva === situacao.value && (
                            <div
                              className="linha"
                              style={{ background: cores[situacaoAtiva] }}
                            ></div>
                          )}
                        </div>
                      </>
                    );
                  })}
            </div>
          </div>
        )}
        <div
          className={`containerTable grab ${
            pedidosSelecionados.length && "paddingExtra"
          }`}
        >
          {loadingSkeleton ? (
            <>
              <SkeletonTable />
            </>
          ) : (
            <Table
              className="table"
              button={true}
              data={
                (pedidosFiltrados !== undefined
                  ? pedidosFiltrados[situacaoAtiva]
                  : pedidos[situacaoAtiva]) || []
              }
              page={currentPage}
              itensPorPagina={itensPorPagina}
              columns={tableColumns}
              sortable={true}
              selectable={true}
              statusColor={cores[situacaoAtiva]}
              onItemSelected={(item) => {
                setPedidosSelecionados(item);
              }}
              onButtonClicked={(item: PedidoListagem) =>
                handleDetalhesPedido(item)
              }
              onDoubleClicked={(item: PedidoListagem) =>
                handleDetalhesPedido(item)
              }
            />
          )}
        </div>
        {!loadingSkeleton && !loading && (
          <FooterPaginas animation={true} key={animationKey}>
            <div className="paginacao-table">
              <div className="container-paginacao-table">
                <div className="flex-container">
                  {!pedidosSelecionados.length ? (
                    <span>
                      <Paginacao
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePaginacao}
                      ></Paginacao>
                    </span>
                  ) : (
                    <>
                      <span>
                        {pedidosSelecionados.length}{" "}
                        {pedidosSelecionados.length > 1
                          ? "selecionados"
                          : "selecionado"}
                      </span>
                      <span className="changeSituacao">
                        <div className="flexItens">
                          {situacaoAtiva !== "3" && (
                            <>
                              <span>Alterar situação para:</span>
                              <span className="select">
                                <SelectPedidos
                                  options={
                                    situacaoAtiva ===
                                    situacoes
                                      .find(
                                        (situacao) =>
                                          situacao.name === "Excluído"
                                      )
                                      ?.value.toString()
                                      ? situacoes
                                      : situacoes.filter(
                                          (situacao) =>
                                            situacao.name !== "Excluído"
                                        )
                                  }
                                  value={situacaoAtiva}
                                  onChange={handleMoverPedidos}
                                  mostrarQuantidade={false}
                                  openUp={true}
                                  cores={cores}
                                ></SelectPedidos>
                              </span>
                            </>
                          )}
                          <Button
                            title="Imprimir pedidos"
                            className="botao"
                            onClick={() => handleImprimirPedidos()}
                          />
                        </div>
                      </span>
                    </>
                  )}

                  <span className="valor">
                    {pedidos[situacaoAtiva] &&
                      pedidos[situacaoAtiva].length > 0 &&
                      camposAtivos.includes("valor_total") && (
                        <div className="valor-flex">
                          <span className="texto">Valor total:</span>
                          <span>
                            {(pedidos[situacaoAtiva] &&
                              pedidos[situacaoAtiva]
                                .reduce((acumulador: number, pedido: any) => {
                                  return acumulador + pedido.valor_total || 0;
                                }, 0)
                                .toFixed(2)
                                .replace(".", ",")) ||
                              ""}
                          </span>
                        </div>
                      )}
                    {pedidosSelecionados && pedidosSelecionados.length > 0 && (
                      <>
                        <div className="valor-flex">
                          <span className="texto">Selecionado:</span>
                          <span>
                            {pedidosSelecionados
                              .reduce((acumulador: number, pedido: any) => {
                                return acumulador + pedido.valor_total || 0;
                              }, 0)
                              .toFixed(2)
                              .replace(".", ",") || ""}
                          </span>
                        </div>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </FooterPaginas>
        )}
      </ContainerPedidos>
    </>
  );
};

export default Pedidos;
