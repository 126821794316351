import { BlipChat } from "blip-chat-widget";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as BlipIcon } from "../../assets/newImages/icons/blip.svg";
import { ReactComponent as PlusIcon } from "../../assets/newImages/icons/plus.svg";
import { EmpresaContext } from '../../contexts/Empresa/EmpresaContext';
import { ScrollDirectionContext } from '../../contexts/ScrollDirection/ScrollDirectionContext';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import { isHorarioComercial } from '../../services/Functions';
import './styles';
import { BlipChatContainer } from './styles';


interface BlipChatComponentProps {
}

const BlipChatComponent: React.FC<BlipChatComponentProps> = ({}) => {

    const scrollDir = useContext(ScrollDirectionContext).direction;
    const {theme} = useTheme();
    const { empresa, setEmpresa } = useContext(EmpresaContext);
    const mainContainerRef = useRef<any>(null);
    const blipClientRef = useRef<any>(null);
    const blipClientContainerRef = useRef<any>(null);
    const tentativasSeguidas = useRef<number>(0);
    const [isChatOpen, setIsChatOpen] = useState(false);
    
    const mountBlipChat = () => {
        if(isHorarioComercial() && !blipClientRef.current && blipClientContainerRef.current && empresa && empresa.usuario){
            try {
                const customStyleSheet = `
                    #app {
                        background-color: ${theme.colors.background1} !important;
                    }
                    #message-input textarea, #message-input{
                        background-color: ${theme.colors.background2} !important;
                    }
                    #blip-chat-header, #blip-send-message {
                        background-color: ${theme.colors.brandPrimary} !important;
                        color: ${theme.colors.white} !important;
                    }
                    #media-link {
                        background-color: transparent !important;
                        border-color: transparent !important;
                    }
                    #message-input textarea {
                        color: ${theme.colors.brandSecondary} !important;
                    }
                    div.bubble.right, .select .options li {
                        background-color: ${theme.colors.brandPrimary} !important;
                        border-color: ${theme.colors.brandPrimary} !important;
                    }
                    div.bubble.right div, .select .options li div{
                        color: ${theme.colors.white} !important;
                    }

                    div.bubble.left {
                        background-color: ${theme.colors.elements} !important;
                        border-color: ${theme.colors.elements} !important;
                    }
                    div.bubble.left div {
                        color: ${theme.colors.brandSecondary} !important;
                    }

                `;
                blipClientRef.current = new BlipChat();
                blipClientRef.current
                .withAppKey('Y29uZWN0YXRlc3RlOjIxOWIxZTczLWU1NGYtNDRjNC05MDg4LWNiMjg5NWU3ODdlNQ')
                .withCustomCommonUrl('https://macropublicidade.chat.blip.ai/')
                .withTarget(blipClientContainerRef.current.id)
                .withCustomStyle(customStyleSheet)
                .withAuth({
                    authType: BlipChat.DEV_AUTH,
                    userIdentity: empresa?.usuario,
                    userPassword: 'any',
                })
                .withCustomMessageMetadata ({
                    "origem do usuario" : "Painel ConectaVenda"
                 })
                .withAccount({
                    fullName: empresa?.usuario,
                    email: empresa?.email,
                    phoneNumber: empresa?.telefone,
                    city: `${empresa?.cidade} - ${empresa?.estado}`,
                    extras: {
                        "Empresa": empresa?.empresa,
                    }
                })
                .build();
                tentativasSeguidas.current = 0;
            } catch (error) {
                tentativasSeguidas.current++;
                if(tentativasSeguidas.current < 4){
                    setTimeout(() => {
                        mountBlipChat();
                    }, 1000);
                }
            }
        }
    }

    const unmountBlipChat = async () => {
        return new Promise((resolve, reject) => {
            if (blipClientRef.current && typeof blipClientRef.current.destroy === 'function') {
                try {
                    blipClientRef.current.destroy();
                    blipClientRef.current = null;
                    resolve(true);
                } catch (error) {
                    console.error('Error destroying blipClient:', error);
                    reject(error);
                }
            }
        });
    };

    const toogleBlipChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    useEffect(() => {
        return () => {
            unmountBlipChat();
        };
    }, []);

    useEffect(() => {
        const remountBlipChat = async () => {
            await unmountBlipChat();
            mountBlipChat();
        }
        remountBlipChat();
    }, [theme.mode]);

    useEffect(() => {
        mountBlipChat();
    }, [empresa, blipClientContainerRef.current]);

    return (
        <>
            <BlipChatContainer theme={theme} ref={mainContainerRef}>
                <div className="relative-container">
                    <div className={`floating-button 
                        ${scrollDir == 'down' && !isChatOpen ? 'hidden-bottom' : ''}`
                    }>
                        <div className="blip-icon-container" onClick={toogleBlipChat}>
                            {isChatOpen ? (
                                <PlusIcon className='plus-icon'/>
                            ) : (
                                <BlipIcon className='blip-icon'/>
                            )}  
                        </div>
                        <div className={`chat-container ${isChatOpen && 'active'}`} ref={blipClientContainerRef} id="blip-chat-main-container">

                        </div>
                    </div>
                </div>
            </BlipChatContainer >
        </>
    )
}

export default BlipChatComponent;