import React, { useContext, useEffect } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Input } from "../../Input";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { ContainerEntregaLocal } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { cepMask, currencyMask } from "../../../services/Masks";
import { validateCep } from "../../../services/MasksValidations";
import { unmaskToNumbers } from "../../../services/Unmasks";

interface EntregaLocalProps {
  configIn: "empresa" | "configuracoes";
}

const EntregaLocal: React.FC<EntregaLocalProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  const [modalTaxa, setModalTaxa] = React.useState(false);
  const [configuracaoTaxaAuxiliar, setConfiguracaoTaxaAuxiliar] =
    React.useState<number>(0);
  const [isTaxaValid, setIsTaxaValid] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(false);

  useEffect(() => {
    if (!configuracoes.frete.entregaLocal.cepDoLocal) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
          ...configuracoes.frete,
          entregaLocal: {
            ...configuracoes.frete.entregaLocal,
            cepDoLocal: configuracoes.dados_empresa.endereco.cep,
          },
        },
      });
    }
    if (
      !Number.parseFloat(configuracoes.frete.entregaLocal.comTaxa.valor) &&
      configuracoes.frete.entregaLocal.comTaxa.ativo
    ) {
      setIsTaxaValid(false);
    } else {
      setIsTaxaValid(true);
    }
  }, [configuracoes.frete.entregaLocal.comTaxa]);

  return (
    <>
      <ContainerEntregaLocal configIn={configIn} theme={theme}>
        <div className="containerEntregaLocal">
          {configIn === "empresa" && (
            <Titulo
              justifyContent="center"
              flexDirection="column-reverse"
              titulo="Entrega local"
              subtitulo={`Configure CEP de origem e taxa`}
            />
          )}
          <div className="dropdown">
            <div className="cepContainer">
              CEP do local
              <div className="containerInput">
                <Input
                  name="cepOrigem"
                  type="text"
                  value={configuracoes.frete.entregaLocal.cepDoLocal}
                  placeholder={"99200-000"}
                  mask={{
                    mask: cepMask,
                    maskValidation: validateCep,
                    unmask: unmaskToNumbers,
                  }}
                  required={true}
                  ignoreValidations={!configuracoes.frete.entregaLocal.ativo}
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      frete: {
                        ...configuracoes.frete,
                        entregaLocal: {
                          ...configuracoes.frete.entregaLocal,
                          cepDoLocal: newValue,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="togglesContainer">
              <div className="togglesInnerContainer">
                <ToggleConfigurar
                  required={!configuracoes.frete.entregaLocal.comTaxa.ativo}
                  nome="Sem taxa"
                  value={configuracoes.frete.entregaLocal.semTaxa}
                  ignoreValidations={!configuracoes.frete.entregaLocal.ativo}
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      frete: {
                        ...configuracoes.frete,
                        entregaLocal: {
                          ...configuracoes.frete.entregaLocal,
                          semTaxa: newValue,
                          comTaxa: {
                            ...configuracoes.frete.entregaLocal.comTaxa,
                            ativo: false,
                          },
                        },
                      },
                    });
                  }}
                />
                <ToggleConfigurar
                  nome="Com taxa"
                  value={configuracoes.frete.entregaLocal.comTaxa.ativo}
                  required={!configuracoes.frete.entregaLocal.semTaxa}
                  isValid={isTaxaValid}
                  invalidText="Configure uma taxa."
                  ignoreValidations={!configuracoes.frete.entregaLocal.ativo}
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      frete: {
                        ...configuracoes.frete,
                        entregaLocal: {
                          ...configuracoes.frete.entregaLocal,
                          comTaxa: {
                            ...configuracoes.frete.entregaLocal.comTaxa,
                            ativo: newValue,
                          },
                          semTaxa: false,
                        },
                      },
                    });
                  }}
                  onConfigurar={() => {
                    setModalTaxa(true);
                  }}
                />
              </div>
            </div>
            {modalTaxa && (
              <ModalRelativo
                cancelar={true}
                width="50rem"
                maxWidth="90%"
                normalModal={configIn === "configuracoes" ? true : false}
                center={true}
                isOpen={modalTaxa}
                setIsOpen={setModalTaxa}
                titulo={"Taxa"}
                subtitulo={"Entrega local"}
                onUseEffect={() => {
                  setConfiguracaoTaxaAuxiliar(
                    Number.parseFloat(
                      configuracoes.frete.entregaLocal.comTaxa.valor,
                    ),
                  );
                }}
                onCancel={() => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      entregaLocal: {
                        ...configuracoes.frete.entregaLocal,
                        comTaxa: {
                          ...configuracoes.frete.entregaLocal.comTaxa,
                          valor: configuracaoTaxaAuxiliar.toString(),
                        },
                      },
                    },
                  });
                }}
                disabled={
                  Number.parseFloat(
                    configuracoes.frete.entregaLocal.comTaxa.valor,
                  ) <= 0
                    ? true
                    : false
                }
                disabledMessage="Preencha uma taxa"
              >
                <div className="inputContainer">
                  Taxa
                  <div>
                    <Input
                      name="cepOrigem"
                      type="text"
                      mask={{
                        mask: currencyMask,
                        unmask: unmaskToNumbers,
                      }}
                      value={
                        Number.parseFloat(
                          configuracoes.frete.entregaLocal.comTaxa.valor,
                        ) != 0
                          ? configuracoes.frete.entregaLocal.comTaxa.valor.toString()
                          : ""
                      }
                      placeholder={"R$0,00"}
                      onChange={(newValue) => {
                        setConfiguracoes({
                          ...configuracoes,
                          frete: {
                            ...configuracoes.frete,
                            entregaLocal: {
                              ...configuracoes.frete.entregaLocal,
                              comTaxa: {
                                ...configuracoes.frete.entregaLocal.comTaxa,
                                valor: newValue != "" ? newValue : "0",
                              },
                            },
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </ModalRelativo>
            )}
          </div>
        </div>
      </ContainerEntregaLocal>
    </>
  );
};
export default EntregaLocal;
